import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ProgressIndicator } from '@japieglobal/shared/src/components';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { ResponsiveLineCanvas } from '@nivo/line';
import { Colors } from '@japieglobal/shared/src/style/palettes/theme-colors';
import { getCarClicks } from '@japieglobal/shared/src/api';
import { useTranslation } from 'react-i18next';

const margin = {
  left: 25,
  bottom: 25,
  top: 10,
  right: 5,
};

const Wrapper = styled(`div`)({
  height: '100px',
  width: '250px',
  position: 'relative',
});

interface ChartPoint {
  id: string;
  data: { x: number; y: number | null }[];
}

export function CarClickChartTiny(props: { car: CarResult }) {
  const { t } = useTranslation();
  const { car } = props;

  const [carClicks, setCarClicks] = useState<number[]>([]);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [max, setMax] = useState<number>(0);

  useDebouncedEffect(
    () => {
      if (car.id && !carClicks.length) {
        getCarClicks({ carId: String(car.id) })
          .then((x) => {
            setMax(Math.max(...x));
            return setCarClicks(x);
          })
          .catch(snackbarErrorHandler);
      }
    },
    1000,
    [car, carClicks, snackbarErrorHandler],
  );

  const sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  };

  const data = useMemo(() => {
    if (carClicks.length <= 1) return undefined;
    const carDiscountDates = car.price?.history?.map((h) => {
      const dateStringParts = h.label.split('/');
      return new Date(Number(dateStringParts[2]), (Number(dateStringParts[1]) - 1) % 12, Number(dateStringParts[0]));
    });
    const discountPoints: ChartPoint = { id: 'DISCOUNT_CLICKS', data: [] };
    const regularPoints: ChartPoint = { id: 'CLICKS', data: [] };
    [...carClicks].forEach((click, index) => {
      const date = new Date(new Date().setDate(new Date().getDate() - carClicks.length + index));
      const point = { x: index, y: click };
      regularPoints.data.push(point);
      if (carDiscountDates?.some((discount) => sameDay(date, discount))) {
        discountPoints.data.push(point);
      } else {
        discountPoints.data.push({ x: index, y: null });
      }
    });
    return [regularPoints, discountPoints];
  }, [car.price?.history, carClicks]);

  const hideChart = carClicks.length > 0 && !carClicks.some((click) => click > 0);
  if (hideChart) return null;
  return (
    <Wrapper>
      {carClicks.length > 1 ? (
        <ResponsiveLineCanvas
          data={data!}
          margin={margin}
          axisLeft={{
            tickValues: [0, Math.floor(max / 2), max],
          }}
          colors={[Colors.GREEN, 'red']}
          xScale={{ type: 'linear' }}
          axisBottom={{
            tickValues: [0, Math.floor(carClicks.length / 2)],
            format: (v) => `${v - carClicks.length} ${t('DAYS')}`,
          }}
          yScale={{ type: 'linear', min: 0, max, stacked: false }}
          pointSize={4}
        />
      ) : (
        <ProgressIndicator />
      )}
    </Wrapper>
  );
}
