import { useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { AuthInfoTextWithSmallMargin, AuthLink } from './auth-styled-components';
import { AuthStyleContainer } from './auth-style-container';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { MuiTelInput } from 'mui-tel-input';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from '../utils/validate-phone-number';
import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { resetPhoneNumber } from '@japieglobal/shared/src/api';

export const AuthResetPhonePage = () => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [alertMessage, setAlertMessage] = useState('');

  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchParams] = useAppSearchParams();
  const token = searchParams[AppSearchParamKeys.TOKEN];

  const submit = useCallback(() => {
    if (!token || !newPhoneNumber) {
      setAlertMessage(`Please fill phone number`);
      return;
    }
    resetPhoneNumber({ token, phone_number: newPhoneNumber })
      .then(() => navigate('/login', { replace: true }))
      .catch(snackbarErrorHandler);
  }, [navigate, newPhoneNumber, snackbarErrorHandler, token]);

  return (
    <AuthStyleContainer alertMessage={alertMessage}>
      <AuthInfoTextWithSmallMargin>Kies een nieuw telefoon nummer.</AuthInfoTextWithSmallMargin>
      <MuiTelInput
        style={{ marginLeft: '10px' }}
        value={newPhoneNumber}
        onChange={(value) => setNewPhoneNumber(value)}
        defaultCountry="NL"
      />
      <Button disabled={!isValidPhoneNumber(newPhoneNumber)} onClick={submit}>
        {t('SAVE_PHONE_NUMBER')}
      </Button>
      <AuthLink to="/login">← Terug naar login</AuthLink>
    </AuthStyleContainer>
  );
};
