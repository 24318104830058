import React from 'react';
import { SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import {
  CsmReport,
  CsmReportKey,
  DealerAccountStatus,
  SalesReportRow,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

interface StatusSelectCellProps {
  row: CsmReport | SalesReportRow;
  onDealerUpdated: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}
export const statusOptions = [
  { value: 'FOCUS', label: 'F' },
  { value: 'NORMAL', label: 'N' },
  { value: 'PILOT', label: 'P' },
];

export const StatusSelectCell = ({ setRowsIsLoading, onDealerUpdated, row }: StatusSelectCellProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const handleSelect = (selectedOptions: SelectOption) => {
    setRowsIsLoading(true);
    updateDealerAccount({
      name: row[CsmReportKey.DEALER],
      csm: row[CsmReportKey.CSM],
      health: row[CsmReportKey.HEALTH],
      [CsmReportKey.STATUS]: selectedOptions.value as DealerAccountStatus,
    })
      .then((res) => onDealerUpdated(res))
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
  };

  const getValueOptions = (item?: DealerAccountStatus) => statusOptions.find((searchItem) => searchItem.value === item);

  return (
    <SelectInput value={getValueOptions(row[CsmReportKey.STATUS])} setValue={handleSelect} options={statusOptions} />
  );
};
