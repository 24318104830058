import { useCallback, useMemo } from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { urlParseBooleanWithNullify } from '../utils/url-value-parsers';
import { ClickState } from '@japieglobal/shared/src/types/click-state';

export type ClickValueObj = { value: boolean; setter: (val: boolean | undefined) => void };
export type ClickValueUndefinedObj = { value: boolean | undefined; setter: (val: boolean | undefined) => void };
export type ClickStateObj = { value: ClickState | undefined; setter: (val: ClickState | undefined) => void };
export type ClickSpecialValueObj = { value: string | undefined; setter: (val: string | undefined) => void };
export type ClickDealerValueObj = { value: string | undefined; setter: (val: string | undefined) => void };

export type ClicksPassedDown = {
  clickOwnSupply: ClickValueObj;
  clickModelYear: ClickValueUndefinedObj;
  clickBuild: ClickValueObj;
  clickStatLocalCar: ClickValueObj;
  clickHP: ClickValueObj;
  equipmentInStock: ClickStateObj;
  equipmentIsDealer: ClickValueObj;
  equipmentOfficialMake: ClickValueObj;
  options: ClickSpecialValueObj;
  includeBanned: ClickValueObj;
  dealer: ClickDealerValueObj;
  location: ClickValueUndefinedObj;
  polygon: ClickValueUndefinedObj;
};

export const useClickQueryStates = () => {
  const [clickOwnSupply, setClickOwnSupply] = useQueryString<boolean | undefined>(
    'click_own_supply',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );
  const [clickModelYear, setClickModelYear] = useQueryString<boolean | undefined>(
    'click_model_year',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickBuild, setClickBuild] = useQueryString<boolean | undefined>(
    'click_build',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickHorsePower, setClickHorsePower] = useQueryString<boolean | undefined>(
    'click_hp',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickEquipmentInStock, setClickEquipmentInStock] = useQueryString<ClickState | undefined>(
    'click_equipment_in_stock',
    undefined,
  );

  const [clickEquipmentIsDealer, setClickEquipmentIsDealer] = useQueryString<boolean | undefined>(
    'click_equipment_is_dealer',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickEquipmentOfficialMake, setClickEquipmentOfficialMake] = useQueryString<boolean | undefined>(
    'click_equipment_official_make',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickStatLocalCar, setClickStatLocalCar] = useQueryString<boolean | undefined>(
    'click_stat_local_car',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickOptions, setClickOptions] = useQueryString<string | undefined>('click_options', undefined);

  const [clickIncludeBanned, setClickIncludeBanned] = useQueryString<boolean | undefined>(
    'include_banned',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickLocation, setClickLocation] = useQueryString<boolean | undefined>(
    'click_location',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickPolygon, setClickPolygon] = useQueryString<boolean | undefined>(
    'click_polygon',
    undefined,
    false,
    urlParseBooleanWithNullify,
  );

  const [clickDealer, setClickDealer] = useQueryString<string | undefined>('click_dealer', undefined);

  const clickQuerySetters = useMemo(
    () => ({
      setClickStatLocalCar,
      setClickEquipmentInStock,
      setClickHorsePower,
      setClickBuild,
      setClickModelYear,
      setClickOwnSupply,
      setClickEquipmentIsDealer,
      setClickEquipmentOfficialMake,
      setClickOptions,
      setClickIncludeBanned,
      setClickLocation,
      setClickPolygon,
      setClickDealer,
    }),
    [
      setClickBuild,
      setClickEquipmentInStock,
      setClickEquipmentIsDealer,
      setClickEquipmentOfficialMake,
      setClickHorsePower,
      setClickModelYear,
      setClickOwnSupply,
      setClickStatLocalCar,
      setClickOptions,
      setClickIncludeBanned,
      setClickLocation,
      setClickPolygon,
      setClickDealer,
    ],
  );

  const clickQueryState = useMemo(
    () => ({
      clickOwnSupply,
      clickModelYear,
      clickBuild,
      clickHorsePower,
      clickStatLocalCar,
      clickEquipmentInStock,
      clickEquipmentIsDealer,
      clickEquipmentOfficialMake,
      clickOptions,
      clickIncludeBanned,
      clickDealer,
    }),
    [
      clickBuild,
      clickEquipmentInStock,
      clickEquipmentIsDealer,
      clickEquipmentOfficialMake,
      clickHorsePower,
      clickModelYear,
      clickOwnSupply,
      clickStatLocalCar,
      clickOptions,
      clickIncludeBanned,
      clickDealer,
    ],
  );

  const getClicksFromCar = useCallback(
    (carResult: CarResult | undefined) => {
      const result: ClicksPassedDown = {
        clickBuild: {
          value: clickBuild ?? !!carResult?.click?.build,
          setter: setClickBuild,
        },
        clickHP: {
          value: clickHorsePower ?? !!carResult?.click?.hp,
          setter: setClickHorsePower,
        },
        clickModelYear: {
          value: clickModelYear ?? carResult?.click?.model_year,
          setter: setClickModelYear,
        },
        clickOwnSupply: {
          value: clickOwnSupply ?? !!carResult?.click?.own_supply,
          setter: setClickOwnSupply,
        },
        clickStatLocalCar: { value: !!clickStatLocalCar, setter: setClickStatLocalCar },

        equipmentInStock: {
          value: clickEquipmentInStock ?? carResult?.click?.equipment_in_stock,
          setter: setClickEquipmentInStock,
        },
        equipmentIsDealer: {
          value: clickEquipmentIsDealer ?? !!carResult?.click?.equipment_is_dealer,
          setter: setClickEquipmentIsDealer,
        },
        equipmentOfficialMake: {
          value: clickEquipmentOfficialMake ?? !!carResult?.click?.equipment_official_make,
          setter: setClickEquipmentOfficialMake,
        },
        options: {
          value: clickOptions ?? carResult?.click?.options,
          setter: setClickOptions,
        },
        includeBanned: {
          value: clickIncludeBanned ?? !!carResult?.includes_banned,
          setter: setClickIncludeBanned,
        },
        dealer: {
          value: clickDealer ?? carResult?.click?.dealer,
          setter: setClickDealer,
        },
        location: {
          value: clickLocation ?? carResult?.click?.location,
          setter: setClickLocation,
        },
        polygon: {
          value: clickPolygon ?? carResult?.click?.polygon,
          setter: setClickPolygon,
        },
      };
      return result;
    },
    [
      clickBuild,
      clickEquipmentInStock,
      clickEquipmentIsDealer,
      clickEquipmentOfficialMake,
      clickHorsePower,
      clickModelYear,
      clickOwnSupply,
      clickStatLocalCar,
      clickOptions,
      clickIncludeBanned,
      clickDealer,
      clickLocation,
      clickPolygon,
      setClickBuild,
      setClickEquipmentInStock,
      setClickEquipmentIsDealer,
      setClickEquipmentOfficialMake,
      setClickHorsePower,
      setClickModelYear,
      setClickOwnSupply,
      setClickStatLocalCar,
      setClickOptions,
      setClickIncludeBanned,
      setClickDealer,
      setClickLocation,
      setClickPolygon,
    ],
  );

  const clicksAPIStyle = useMemo(
    () => ({
      click_model_year: clickModelYear,
      click_build: clickBuild,
      click_equipment_in_stock: clickEquipmentInStock,
      click_equipment_is_dealer: clickEquipmentIsDealer,
      click_equipment_official_make: clickEquipmentOfficialMake,
      click_hp: clickHorsePower,
      click_stat_local_car: clickStatLocalCar,
      click_own_supply: clickOwnSupply,
      click_options: clickOptions,
      click_dealer: clickDealer,
      click_location: clickLocation,
      click_polygon: clickPolygon,
      include_banned: clickIncludeBanned,
    }),
    [
      clickBuild,
      clickEquipmentInStock,
      clickEquipmentIsDealer,
      clickEquipmentOfficialMake,
      clickHorsePower,
      clickModelYear,
      clickOwnSupply,
      clickStatLocalCar,
      clickOptions,
      clickIncludeBanned,
      clickDealer,
      clickLocation,
      clickPolygon,
    ],
  );

  return { clickQueryState, clickQuerySetters, getClicksFromCar, clicksAPIStyle };
};
