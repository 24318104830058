import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKey, AppSearchParams, StringListAppSearchParam } from '../../types';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { MultiSelectInput } from '../../../inputs';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { SelectOption } from '../../../inputs/select/select-input/select-input';
import { IsolatedMultiSelectInputProps } from '../../../inputs/select/multi-select-input/multi-select-input';
import { useSnackbarErrorHandler } from '../../../../hooks/snackbar-error-handler';
import { useAppSearchParams } from '../../hooks';
import { CarHeader, carHeader } from '../../../../api';
import { CarHeaderProps } from '../../../../api';

export interface MultiSelectFilterProps
  extends BaseInputFilterProps<SelectOption[], StringListAppSearchParam>,
    IsolatedMultiSelectInputProps {
  carHeaderOptions?: CarHeaderProps;
  staticOptions?: SelectOption[];
  filterDependencies?: AppSearchParams;
  isLoading?: boolean;
  disableAutoSelect?: boolean;
  // The difference between the initialValue and the defaultValue prop,
  // is that the initialValue will only trigger on the first render.
  // However the defaultValue will trigger every time the input is empty.
  initialValue?: SelectOption[];
}

export const MultiSelectFilter = ({
  searchParamKey,
  labelTranslationKey,
  staticOptions,
  filterDependencies,
  carHeaderOptions,
  hideResetButton,
  defaultValue,
  isLoading: isLoadingProp,
  disableAutoSelect = false,
  hasBackendDefault = false,
  disabled,
  initialValue,
  ...rest
}: MultiSelectFilterProps) => {
  const [options, setOptions] = useState<SelectOption[]>(staticOptions || []);
  const [isLoading, setIsLoading] = useState<boolean>(isLoadingProp ?? false);
  const [searchParams, setSearchParams] = useAppSearchParams();
  const [initialRender, setInitialRender] = useState(true);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const value = searchParams[searchParamKey];

  const getSelectedOptions = useCallback(() => {
    return options.filter((option) => value?.includes(option.value));
  }, [options, value]);

  const selectedOption = useMemo(() => {
    const selectedOptions = getSelectedOptions();
    return (selectedOptions.length > 0 && selectedOptions) || defaultValue || [];
  }, [defaultValue, getSelectedOptions]);

  const onHandleReset = useCallback(() => {
    searchParams[searchParamKey] = undefined;
    setSearchParams(searchParams);
  }, [searchParamKey, searchParams, setSearchParams]);

  const onHandleChange = useCallback(
    (selectedOptions: SelectOption[]) => {
      searchParams[searchParamKey] = selectedOptions.map((selectedOption) => selectedOption.value);
      setSearchParams(searchParams);
    },
    [searchParamKey, searchParams, setSearchParams],
  );

  const hasEmptyDependency = useCallback(() => {
    let isEmpty = false;
    for (const property in filterDependencies) {
      if (!filterDependencies[property as AppSearchParamKey]) {
        isEmpty = true;
      }
    }
    return isEmpty;
  }, [filterDependencies]);

  useEffect(() => {
    if (carHeaderOptions && !hasEmptyDependency()) {
      setIsLoading(true);
      carHeader(carHeaderOptions)
        .then(({ headers }) => {
          // To reduce the amount of code and remove type checks,
          // we always use a toString and toUpperCase even tho it might not be necessary
          const translatedOptions = headers.map((option) => ({
            label:
              carHeaderOptions.type === CarHeader.MAKE || carHeaderOptions.type === CarHeader.MODEL
                ? String(option)
                : t(option.toString().toUpperCase(), option.toString()),
            value: option.toString(),
          }));
          setOptions(translatedOptions);
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, carHeaderOptions, staticOptions, hasEmptyDependency, t, filterDependencies]);

  useEffect(() => {
    if (hasEmptyDependency() && value) {
      onHandleReset();
    }
  }, [hasEmptyDependency, onHandleReset, value]);

  useEffect(() => {
    if (value) {
      const optionsValues = Object.values(options).map((o) => o.value);
      if (!value.every((selectedValue) => optionsValues.includes(selectedValue)) && optionsValues.length) {
        const newSelectedValues = options.filter((option) => value.includes(option.value));
        if (newSelectedValues.length > 0) {
          onHandleChange(newSelectedValues);
        } else {
          if (staticOptions === undefined || (staticOptions.length === 0 && !isLoading)) {
            onHandleReset();
          }
        }
      }
    }
  }, [value, onHandleReset, options, searchParamKey, onHandleChange, isLoading, staticOptions]);

  useEffect(() => {
    if (options.length === 1 && !hasEmptyDependency() && value?.[0] !== options[0].value && !disableAutoSelect) {
      onHandleChange([options[0]]);
    }
  }, [value, hasEmptyDependency, onHandleChange, options, disableAutoSelect]);

  useEffect(() => {
    if (!value && defaultValue && !hasBackendDefault) {
      const defaultsToSet = defaultValue.filter((defaultValueOption) =>
        options.find((option) => option.value === defaultValueOption.value),
      );
      defaultsToSet.length > 0 && onHandleChange(defaultsToSet);
    }
  }, [defaultValue, value, onHandleChange, options, hasBackendDefault]);

  useEffect(() => {
    if (staticOptions) {
      setOptions(staticOptions);
    }
  }, [staticOptions]);

  useEffect(() => {
    if (initialValue && selectedOption.length <= 0 && initialRender) {
      onHandleChange(initialValue);
      setInitialRender(false);
    }
  }, [initialRender, initialValue, onHandleChange, selectedOption]);

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onHandleReset}>
      <MultiSelectInput
        disabled={disabled || hasEmptyDependency()}
        defaultValue={defaultValue || []}
        isLoading={isLoading}
        label={t(labelTranslationKey)}
        options={options}
        setValue={onHandleChange}
        value={selectedOption}
        {...rest}
      />
    </InputWrapper>
  );
};
