import { Button, DialogActions, styled } from '@mui/material';
import React, { useState } from 'react';
import { formatDateAsDate, formatDateAsHourAndMinute } from '@japieglobal/shared/src/utils';
import { useChatMessageStyles } from './chat-message-bubble-styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { ConfirmationDialog, TextInput } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { Message } from '@japieglobal/shared/src/api';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CommentBalloonRowWrapper = styled(`div`)({
  display: 'flex',
  marginBottom: '10px',
});

const CommentWrapper = styled(`div`)({
  margin: '20px 0',
});

export const EmptyChatMessageBubble = () => (
  <div
    style={{
      color: 'gray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      minHeight: '30vh',
    }}
  >
    There are no messages
  </div>
);

const EditMessageDialog = ({ msg, onEdit }: { msg: Message; onEdit: (messageId: number, message: string) => void }) => {
  const [editMessage, setEditMessage] = useState(msg.message);
  const [editClicked, setEditClicked] = useState(false);
  const classes = useChatMessageStyles();
  const { t } = useTranslation();
  return (
    <>
      <AutoFixHighIcon className={classes.comment__edit} onClick={() => setEditClicked(true)} />
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={editClicked}
        onClose={() => {
          setEditMessage(msg.message);
          setEditClicked(false);
        }}
      >
        <DialogTitle>{t('EDIT')}</DialogTitle>
        <DialogContent>
          <TextInput value={editMessage} setValue={(value) => setEditMessage(value)} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onEdit(msg.id, editMessage);
              setEditClicked(false);
            }}
          >
            {t('SAVE')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DeleteMessageDialog = ({ msg, onDelete }: { msg: Message; onDelete: (messageId: number) => void }) => {
  const [deleteClicked, setDeleteClicked] = useState(false);
  const classes = useChatMessageStyles();
  const { t } = useTranslation();

  return (
    <>
      <DeleteForeverIcon className={classes.comment__delete} color="warning" onClick={() => setDeleteClicked(true)} />
      {deleteClicked && (
        <ConfirmationDialog
          closeDialog={() => setDeleteClicked(false)}
          title={t('REMOVE_COMMENT')}
          question={t('REMOVE_COMMENT_QUESTION')}
          yesAction={() => onDelete(msg.id)}
          noAction={() => setDeleteClicked(false)}
        />
      )}
    </>
  );
};

interface ChatMessageBubbleOwnProps {
  msg: Message;
  onDelete?: (commentId: number) => void;
  onEdit?: (commentId: number, message: string) => void;
}
export const ChatMessageBubbleOwn = ({ msg, onDelete, onEdit }: ChatMessageBubbleOwnProps) => {
  const classes = useChatMessageStyles();

  return (
    <CommentWrapper key={msg.id}>
      <CommentBalloonRowWrapper>
        <div className={`${classes['comment--own.comment__balloon']} ${classes.comment__balloon}`}>
          {onEdit && <EditMessageDialog msg={msg} onEdit={onEdit} />}
          {onDelete && <DeleteMessageDialog msg={msg} onDelete={onDelete} />}

          <span className={`${classes['comment--own.comment__triangle']} ${classes.comment__triangle}`} />
          <span className={classes.commentText}>{msg.message}</span>
        </div>
        <div className={`${classes['comment--own.comment__user']} ${classes.comment__user}`}>{msg.user}</div>
      </CommentBalloonRowWrapper>
      <div className={`${classes['comment--own.comment__timestamp']} ${classes.comment__timestamp}`}>
        Geplaatst op {formatDateAsDate(new Date(msg.created_at))} om{' '}
        {formatDateAsHourAndMinute(new Date(msg.created_at))}
      </div>
    </CommentWrapper>
  );
};

export const ChatMessageBubbleOther = ({ msg }: { msg: Message }) => {
  const classes = useChatMessageStyles();
  return (
    <CommentWrapper key={msg.id}>
      <CommentBalloonRowWrapper>
        <div className={classes.comment__user}>{msg.user}</div>
        <div className={classes.comment__balloon}>
          <span className={classes.comment__triangle} />
          <span className={classes.commentText}>{msg.message}</span>
        </div>
      </CommentBalloonRowWrapper>
      <div className={classes.comment__timestamp}>
        Geplaatst op {formatDateAsDate(new Date(msg.created_at))} om{' '}
        {formatDateAsHourAndMinute(new Date(msg.created_at))}
      </div>
    </CommentWrapper>
  );
};
