import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { VideoExplanation } from './video-explanation';

export const EtrExplanation = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const getCountryUrl = () => {
    if (user.language == 'nl') return 'https://share.synthesia.io/embeds/videos/f29c7224-4f83-4408-bcb7-4c63767aa928';
    if (user.language == 'de') return 'https://share.synthesia.io/embeds/videos/e0b65406-f0bd-44b0-a60d-8bc26e08dced';
    return 'https://share.synthesia.io/embeds/videos/354f3943-4ff3-43c6-bcb6-5d6461948a48';
  };

  return <VideoExplanation title="ETR" videoUrl={getCountryUrl()} description={t('ETR_VIDEO_EXPLANATION')} />;
};
