import React from 'react';
import moment from 'moment';
import { LinkCell } from './cell-content/link-cell';
import { RenderCellDefParams, Tooltip } from '@japieglobal/shared/src/components';
import { ScoreTable } from './cell-hover/score-table';
import { HoverTable } from './cell-hover/hover-table';
import {
  CsmReport,
  CsmReportKey,
  DealerAccountHealth,
  SalesReportRow,
  SuperAdminResponseType,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api';
import { Grid2 } from '@mui/material';
import { CellRender, CsmColumn } from '../../../shared/types/csm';
import { HealthCell } from '../../../shared/admin-and-sales-report/cells/health-cell';
import { MessagesCell } from '../../../shared/admin-and-sales-report/cells/messages-cell/messages-cell';
import { CsmSelectCell } from '../../../shared/admin-and-sales-report/cells/csm-select-cell';
import { HealthSelectCell } from '../../../shared/admin-and-sales-report/cells/health-select-cell';
import { StatusSelectCell } from '../../../shared/admin-and-sales-report/cells/status-select-cell';

interface CellContentProps {
  cellRender: CellRender;
  renderCellParams: RenderCellDefParams<CsmReport | SalesReportRow>;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}
interface CellRenderWrapperProps {
  renderCellParams: RenderCellDefParams<CsmReport>;
  csmColumn: CsmColumn;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

const CellContent = ({
  setRowsIsLoading,
  onDealerUpdated,
  cellRender,
  renderCellParams,
  superAdmins,
}: CellContentProps) => {
  return (
    <>
      {cellRender === 'basic' && <>{renderCellParams.value}</>}
      {cellRender === 'date' && <>{renderCellParams.value && moment(renderCellParams.value).format('DD-MM-YYYY')}</>}
      {cellRender === 'health' && (
        <Grid2 container>
          <HealthCell value={renderCellParams.value as DealerAccountHealth} />
        </Grid2>
      )}
      {cellRender === 'comments' && (
        <MessagesCell messages={renderCellParams.value} dealerAccountName={renderCellParams.row[CsmReportKey.DEALER]} />
      )}
      {cellRender === 'link' && <LinkCell links={renderCellParams.value} />}
      {cellRender === 'csmSelect' && (
        <CsmSelectCell
          setRowsIsLoading={setRowsIsLoading}
          onDealerUpdated={onDealerUpdated}
          superAdmins={superAdmins}
          row={renderCellParams.row}
        />
      )}
      {cellRender === 'healthSelect' && (
        <HealthSelectCell
          setRowsIsLoading={setRowsIsLoading}
          onDealerUpdated={onDealerUpdated}
          row={renderCellParams.row}
        />
      )}
      {cellRender === 'statusSelect' && (
        <StatusSelectCell
          setRowsIsLoading={setRowsIsLoading}
          onDealerUpdated={onDealerUpdated}
          row={renderCellParams.row}
        />
      )}
    </>
  );
};

export const CellRenderWrapper = ({
  renderCellParams,
  csmColumn: { cellRender, hoverRender, field },
  superAdmins,
  onDealerUpdated,
  setRowsIsLoading,
}: CellRenderWrapperProps) => (
  // Even tho CellContent is here used 3 times the exact same way,
  // making a separate component out of it to re-use will cause the clicks to stop working in the table.
  // Thats why we accepted a little duplicated code here.
  <>
    {hoverRender === 'score' && (
      <Tooltip
        onHoverContent={<ScoreTable hoverValues={renderCellParams.row[field] as { [key: string]: number | string }} />}
      >
        <CellContent
          setRowsIsLoading={setRowsIsLoading}
          onDealerUpdated={onDealerUpdated}
          superAdmins={superAdmins}
          cellRender={cellRender}
          renderCellParams={renderCellParams}
        />
      </Tooltip>
    )}
    {hoverRender === 'table' && (
      <Tooltip
        onHoverContent={<HoverTable hoverValues={renderCellParams.row[field] as { [key: string]: number | string }} />}
      >
        <CellContent
          setRowsIsLoading={setRowsIsLoading}
          onDealerUpdated={onDealerUpdated}
          superAdmins={superAdmins}
          cellRender={cellRender}
          renderCellParams={renderCellParams}
        />
      </Tooltip>
    )}
    {!hoverRender && (
      <CellContent
        setRowsIsLoading={setRowsIsLoading}
        onDealerUpdated={onDealerUpdated}
        superAdmins={superAdmins}
        cellRender={cellRender}
        renderCellParams={renderCellParams}
      />
    )}
  </>
);
