import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminTableHeaderField } from '../shared/styled';
import { useTimeSpan } from '../shared/period-select';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminQueryStateType } from '../hooks/admin-query-state';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { AdminUserChartNivo } from './admin-user-chart-nivo';
import { renderCellExpand } from './data-grid-render-cell-expand';
import { DownloadIcon } from '../shared/icons';
import { dealerStatisticList, dealerStats, dealerStatsAllDownload } from '@japieglobal/shared/src/api';
import {
  SuperAdminResponseType,
  UserFilterItem,
  UserStaticsListResponse,
  UserStatsResponse,
} from '@japieglobal/shared/src/api';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-colCellTitle': {
      fontSize: '12px',
      color: 'rgba(0,0,0,.54)',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '13px',
      color: 'rgba(0,0,0,.87)',
    },
  },
  smallPadding: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
  },
  warningWrapper: { color: '#F49F00' },
}));

const StyledDataGrid = styled(DataGrid)({
  'overflow': undefined,
  'backgroundColor': 'white',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'white',
  },
});

type SuperAdminResponseTypeForRows = SuperAdminResponseType & {
  id: string;
  lastActivity: Date | undefined;
};

const TableHeaderWrapper = styled(`div`)({
  marginBottom: '2px',
});

const defaultEmptyRecord = {};

interface AdminMainDealerProps {
  adminQueryState: AdminQueryStateType;
}
export const AdminMainDealer = React.memo(({ adminQueryState }: AdminMainDealerProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { page, setSelectedProxy, setPageProxy } = adminQueryState;
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);

  const [chartData, setChartData] = useState<UserStatsResponse | undefined>(undefined);

  const dealerRef = useRef<HTMLInputElement | null>(null);

  const [users, setUsers] = useState<UserStaticsListResponse | undefined>(undefined);

  const { selectPeriod, dateFrom, dateTo } = useTimeSpan();
  const [filters, setFilters] = useState<UserFilterItem[]>([]);

  const requestFilters = useMemo(() => (filters && filters.length > 0 ? filters : undefined), [filters]);

  const [limit, setLimit] = useState(50);

  const [filterModelQuery, setFilterModelQuery] = useQueryString<Record<string, any> | undefined>(
    'userFilter',
    defaultEmptyRecord,
  );

  const [sortModelQuery, setSortModelQuery] = React.useState<GridSortModel>([
    {
      field: 'lastActivity',
      sort: 'desc',
    },
  ]);

  const [sortModel, setSortModel] = React.useState<GridSortModel>(sortModelQuery || []);

  useEffect(() => {
    if (sortModel) {
      setSortModelQuery(sortModel);
    }
  }, [sortModel, setSortModelQuery]);

  useDebouncedEffect(
    () => {
      if (filterModelQuery) {
        const dealerVal = dealerRef.current;
        if (dealerVal) {
          dealerVal.value = filterModelQuery.dealer ?? '';
        }
      }
    },
    300,
    [filterModelQuery],
  );

  const offset = useMemo(() => {
    if (page) {
      return page * limit;
    }
    return 0;
  }, [limit, page]);

  const rows: SuperAdminResponseTypeForRows[] = useMemo(() => {
    if (users?.data) {
      return users.data.map((item) => {
        return {
          ...item,
          // eslint-disable-next-line no-underscore-dangle
          id: item.id,
          lastActivity: item.lastActivity ? new Date(item.lastActivity) : undefined,
        };
      });
    }
    return [];
  }, [users]);

  const onClickDownload = useCallback(() => {
    dealerStatsAllDownload({
      date_from: dateFrom,
      date_to: dateTo,
      filters: requestFilters,
    }).catch(snackbarErrorHandler);
  }, [dateFrom, dateTo, requestFilters, snackbarErrorHandler]);

  useDebouncedEffect(
    () => {
      if (dateFrom || dateTo) {
        setIsLoadingChart(true);
        dealerStats({
          date_from: dateFrom,
          date_to: dateTo,
          filters: requestFilters,
        })
          .then(setChartData)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoadingChart(false));
      }
    },
    300,
    [requestFilters, snackbarErrorHandler, dateFrom, dateTo],
  );

  const refreshUserList = useCallback(() => {
    setIsLoading(true);
    const sort = sortModel.map((s) => [s.field === 'lastActivityDate' ? 'lastActivity' : s.field, s.sort]);
    dealerStatisticList({
      filters: requestFilters,
      limit,
      offset,
      date_from: dateFrom,
      date_to: dateTo,
      sort,
    })
      .then(setUsers)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [sortModel, requestFilters, limit, offset, dateFrom, dateTo, setUsers, snackbarErrorHandler]);

  useDebouncedEffect(() => refreshUserList(), 300, [requestFilters, limit, offset, refreshUserList]);

  useEffect(() => {
    if (filterModelQuery) {
      const resultFilters: UserFilterItem[] = [];
      Object.entries(filterModelQuery).forEach(([key, val]) => {
        resultFilters.push({ filterOn: key, filter: val.trim() });
      });
      setFilters(resultFilters);
    }
  }, [filterModelQuery, setFilters]);

  const headerHitEnter = useCallback(() => {
    const resultFilters: UserFilterItem[] = [];
    const dealerVal = dealerRef.current?.value;
    if (dealerVal && dealerVal.trim().length > 0) {
      resultFilters.push({ filterOn: 'dealer', filter: dealerVal.trim() });
    }
    setFilterModelQuery(
      resultFilters.reduce((previousValue: Record<string, any>, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        previousValue[currentValue.filterOn] = currentValue.filter;
        return previousValue;
      }, {}),
    );
    setPageProxy(0);
  }, [setFilterModelQuery, setPageProxy]);

  const dealerHeader = useCallback(() => {
    const shrink = filterModelQuery?.dealer != null || undefined;
    return (
      <TableHeaderWrapper>
        <AdminTableHeaderField
          autoComplete="off"
          label="Dealerholding"
          type="search"
          InputLabelProps={{ shrink }}
          onKeyPress={(event) => (event.key === 'Enter' ? headerHitEnter() : undefined)}
          inputRef={dealerRef}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </TableHeaderWrapper>
    );
  }, [filterModelQuery?.dealer, headerHitEnter]);

  const onSelectionChange = useCallback(
    (param: any) => {
      setSelectedProxy(param);
    },
    [setSelectedProxy],
  );

  const onPageChange = useCallback(
    (param: any) => {
      setPageProxy(param);
    },
    [setPageProxy],
  );
  const onPageSizeChange = useCallback((param: any) => setLimit(param), [setLimit]);

  const onPaginationModelChange = useCallback(
    (param: GridPaginationModel) => {
      onPageChange(param.page);
      onPageSizeChange(param.pageSize);
    },
    [onPageChange, onPageSizeChange],
  );

  const handleSortChange = React.useCallback((sort: GridSortModel) => {
    setSortModel(sort);
  }, []);

  const renderedGrid = useMemo(() => {
    const columns: Array<GridColDef> = [
      {
        field: 'dealer',
        headerName: 'Dealerholding',
        flex: 1,
        renderCell: renderCellExpand,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: dealerHeader,
      },
      { field: 'lastActivity', headerName: t('LAST_ACTIVITY'), width: 180, type: 'dateTime' },
      { field: 'users', headerName: t('USERS'), width: 90 },
      { field: 'totalUser', headerName: t('TOTAL_USERS'), width: 120 },
      { field: 'totalApi', headerName: t('TOTAL_API'), width: 120 },
      { field: 'totalPurchase', headerName: t('TOTAL_PURCHASE'), width: 120 },
      { field: 'totalActivities', headerName: t('TOTAL'), width: 90 },
      { field: 'cars_count', headerName: t('CARS'), width: 90, sortable: false },
    ];
    return (
      <StyledDataGrid
        loading={isLoading}
        autoHeight
        onRowSelectionModelChange={onSelectionChange}
        className={styles.root}
        paginationMode="server"
        rows={rows}
        columnHeaderHeight={60}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableRowSelectionOnClick
        columns={columns}
        onPaginationModelChange={onPaginationModelChange}
        paginationModel={{ page: page, pageSize: limit }}
        rowCount={users?.total}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortChange}
        getRowId={(row) => row.dealer}
      />
    );
  }, [
    dealerHeader,
    t,
    isLoading,
    onSelectionChange,
    styles.root,
    rows,
    onPaginationModelChange,
    page,
    limit,
    users?.total,
    sortModel,
    handleSortChange,
  ]);

  return (
    <AdminMainWrapper title={t('DEALERS')} titleRightComponent={<div>{selectPeriod}</div>}>
      <div>
        <AdminUserChartNivo apiData={chartData} isLoading={isLoadingChart} getUserActivityUrl={(userId) => userId} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <DownloadIcon onClick={onClickDownload} />
      </div>
      {renderedGrid}
    </AdminMainWrapper>
  );
});
