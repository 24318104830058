import React from 'react';
import { HintsProvider } from './components/hints/hints-context';
import { BrowserRouter } from 'react-router-dom';
import { MuiWrapper } from './style';
import { SnackbarProvider } from 'notistack';
import { FeedbackWidget } from './feedback-widget';
import { User } from './api';
import { PropsWithChildren } from 'react';

interface JappieAppWrapperProps {
  user?: User;
  baseNameRouter?: string;
}
export const JapieAppWrapper = ({ children, user, baseNameRouter }: PropsWithChildren<JappieAppWrapperProps>) => (
  <MuiWrapper>
    <BrowserRouter basename={baseNameRouter}>
      <HintsProvider>
        <SnackbarProvider maxSnack={3}>
          <FeedbackWidget user={user} />
          {children}
        </SnackbarProvider>
      </HintsProvider>
    </BrowserRouter>
  </MuiWrapper>
);
