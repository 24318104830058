import React from 'react';
import { SelectFilter, AppSearchParamKeys, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { DataType } from '@japieglobal/shared/src/api';

export const DataTypeFilter = () => {
  const { t } = useTranslation();

  const options: SelectOption[] = Object.values(DataType).map((dataType) => ({ value: dataType, label: t(dataType) }));

  return (
    <SelectFilter
      hideResetButton
      searchParamKey={AppSearchParamKeys.DATA_TYPE}
      labelTranslationKey="DATA"
      staticOptions={options}
      defaultValue={options[0]}
    />
  );
};
