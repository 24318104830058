import { AppSearchParamKeys, SelectFilter, SelectOption, TextFilter } from '@japieglobal/shared/src/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TaxationFilterSearch = () => {
  const { t } = useTranslation();
  const options: SelectOption[] = [
    { value: 'license_plate', label: t('LICENSE_PLATE') },
    { value: 'dealer_name', label: t('DEALER') },
    { value: 'id', label: t('ID') },
  ];
  return (
    <>
      <SelectFilter
        searchParamKey={AppSearchParamKeys.SEARCH_KEY}
        labelTranslationKey={'SEARCH_KEY'}
        staticOptions={options}
      />
      <TextFilter searchParamKey={AppSearchParamKeys.SEARCH_VALUE} labelTranslationKey="SEARCH_VALUE" />
    </>
  );
};
