import { User } from '../api';
import { settings } from '../settings';

export const largeNumberFormatter = (
  user: User | undefined,
  num: number | undefined,
  digits?: number,
  showCompact?: boolean,
) =>
  num != null
    ? Intl.NumberFormat(user?.language, {
        notation: showCompact ? 'compact' : undefined,
        maximumSignificantDigits: digits,
        minimumSignificantDigits: digits,
      }).format(num)
    : 0;

// Also known as the moneyFormatter
export const getMoney = (
  user: User,
  num?: number,
  mainDigits?: number,
  maximumFractionDigits?: number,
  showCompact?: boolean,
  minimumFractionDigits?: number,
  region?: string,
) =>
  Intl.NumberFormat(`${user.language}-${settings(region).country.toUpperCase()}`, {
    notation: showCompact ? 'compact' : undefined,
    style: 'currency',
    currencyDisplay: 'narrowSymbol', // Use narrow symbols where available
    currency: settings(region).currencyCode,
    maximumSignificantDigits: mainDigits,
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: maximumFractionDigits ?? 0,
  }).format(num ?? 0);
