import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ColoredButton } from '@japieglobal/shared/src/components';

export const TaxationFilterTaxationButton = ({ onSubmit }: { onSubmit: (event: FormEvent) => void }) => {
  const { t } = useTranslation();
  return (
    <ColoredButton onClick={onSubmit} type="submit">
      {t('EVALUATE')}
    </ColoredButton>
  );
};
