import React from 'react';
import { TextFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';

export const TaxationFilterLicensePlate = () => (
  <Grid2 size={2}>
    <TextFilter
      tabOnEnter
      searchParamKey={AppSearchParamKeys.LICENSE_PLATE}
      labelTranslationKey="LICENSE_PLATE"
      autoFocus
      hideResetButton
    />
  </Grid2>
);
