import React, { useContext } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { HintsContext } from '../../../hints/hints-context';
import { MenuButton } from '../../menu-button';

export const HintsButton = () => {
  const { hintsEnabled, setHints } = useContext(HintsContext);

  return (
    <MenuButton hoverTitle={'HINT'} onClick={() => setHints(!hintsEnabled)}>
      <QuestionMarkIcon />
    </MenuButton>
  );
};
