import React from 'react';
import { styled, Typography, Box, Stack } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { PageContainer } from '@japieglobal/shared/src/components';

const StyledWrapper = styled(`div`)({
  position: 'relative',
  overflow: 'hidden',
  paddingTop: '56.25%',
});

const StyledVideoFrame = styled(`iframe`)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  border: 'none',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
});

export const VideoExplanation = ({
  title,
  videoUrl,
  description,
}: {
  title: string;
  videoUrl: string;
  description: string;
}) => {
  return (
    <PageContainer
      hideResetAllButton
      internal
      mainComponent={
        <Box>
          <Box sx={{ mb: 5 }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <SchoolIcon fontSize="large" />
              <Typography sx={{ fontWeight: 'bold', fontSize: 35 }}>{title}</Typography>
            </Stack>
            <Typography sx={{ fontSize: 20 }}>{description}</Typography>
          </Box>
          <StyledWrapper>
            <StyledVideoFrame
              src={videoUrl + '?autoplay=1'}
              loading="lazy"
              title={title}
              allowFullScreen
              allow="encrypted-media; fullscreen;"
            ></StyledVideoFrame>
          </StyledWrapper>
        </Box>
      }
    />
  );
};
