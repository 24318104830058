import React, { useState } from 'react';
import { Box, CircularProgress, Dialog, DialogTitle, List, ListItemText, ListItem } from '@mui/material';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { getGoogleUrls } from '@japieglobal/shared/src/api';
import { ColoredBorderButton } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';

export const GoogleUrls = ({ dealer_name }: { dealer_name: string }) => {
  const { snackbarErrorMessage } = useSnackbarErrorHandler();
  const [urls, setUrls] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const getUrls = () => {
    if (!dealer_name) {
      snackbarErrorMessage('FILL_DEALER_NAME');
    } else {
      setShowDialog(true);
      setIsLoading(true);
      getGoogleUrls(dealer_name)
        .then((data) => {
          setUrls(data.split('\n').map((item) => item.split(',')[1]));
          setIsLoading(false);
        })
        .catch(() => {
          setUrls([]);
          snackbarErrorMessage(t('NO_DATA_FOUND'));
        });
    }
  };

  return (
    <Box mb={2}>
      <ColoredBorderButton color="secondary" onClick={getUrls}>
        Regex url
      </ColoredBorderButton>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Urls</DialogTitle>
        {isLoading && <CircularProgress size={20} sx={{ alignSelf: 'center' }} />}
        <List sx={{ pt: 0 }}>
          {urls.map((url) => (
            <ListItem key={url}>
              <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary={url} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  );
};
