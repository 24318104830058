import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColoredButton,
  ItemSelector,
  LoadingWrapper,
  TextInput,
  ToggleInput,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { getUrlParams } from '../utils/searchParamsMapper';
import { exportCars, exportCarsHistory, ExportColumn } from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { formatDateAsBackendDate, isSuperAdmin } from '@japieglobal/shared/src/utils';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '650px',
    },
  },
}));

export const ExportPartialView = React.memo(() => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useAppSearchParams();
  const [viewHistoricExport, setViewHistoricExport] = useState<boolean | undefined>(false);
  const [historicDate, setHistoricDate] = useState<string | undefined>();
  const [noTranslate, setNoTranslate] = useState<boolean | undefined>(false);
  const onDownload = useCallback(async () => {
    setIsLoading(true);
    const urlParams = getUrlParams(searchParams);
    const params = {
      ...urlParams,
      skip_translation: noTranslate,
      dump_hidden: true,
      dump_nonvaluated: true,
      dump_price: true,
      format: 'xlsx',
      lang: user.language,
    };

    if (viewHistoricExport && historicDate) {
      return await exportCarsHistory(historicDate).finally(() => {
        setHistoricDate(undefined);
        setViewHistoricExport(false);
        setIsLoading(false);
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return await exportCars(params).finally(() => {
        setHistoricDate(undefined);
        setViewHistoricExport(false);
        setIsLoading(false);
      });
    }
  }, [noTranslate, searchParams, user.language, viewHistoricExport, historicDate]);

  const { t } = useTranslation();

  const setHistoricExportValues = (value: boolean | undefined) => {
    setViewHistoricExport(value);
    if (value) {
      setHistoricDate(formatDateAsBackendDate(new Date()));
    } else {
      setHistoricDate(undefined);
    }
  };

  return (
    <>
      <ColoredButton onClick={() => setShowExportPopup(true)}>{t('EXPORT')}</ColoredButton>
      <StyledDialog
        fullScreen={fullScreen}
        onClose={() => setShowExportPopup(false)}
        aria-labelledby="export"
        open={showExportPopup}
        disableEnforceFocus
      >
        <LoadingWrapper isLoading={isLoading}>
          <DialogTitle id="customized-dialog-title">{t('EXPORT')}</DialogTitle>
          <DialogContent dividers>
            <ItemSelector language={user.language} type={ExportColumn.HOME} initialCollapseState={false} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <div>
              {isSuperAdmin(user) && (
                <>
                  <ToggleInput
                    label={t('UNTRANSLATED')}
                    fullWidth={false}
                    toggleState={noTranslate}
                    disableTripleState
                    setToggleState={setNoTranslate}
                  />
                  <ToggleInput
                    label={t('HISTORIC_EXPORT')}
                    fullWidth={false}
                    toggleState={viewHistoricExport}
                    disableTripleState
                    setToggleState={setHistoricExportValues}
                  />
                </>
              )}
              {viewHistoricExport && <TextInput required width={200} value={historicDate} setValue={setHistoricDate} />}
            </div>
            <ColoredButton disabled={isLoading} autoFocus onClick={onDownload} color="primary">
              {t('DOWNLOAD')}
            </ColoredButton>
          </DialogActions>
        </LoadingWrapper>
      </StyledDialog>
    </>
  );
});
