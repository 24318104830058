import { useSnackbarErrorHandler } from '../../../../hooks/snackbar-error-handler';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKey, AppSearchParams, NumberAppSearchParam, StringAppSearchParam } from '../../types';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { SelectInput } from '../../../inputs';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { emptySelectOption, SelectOption } from '../../../inputs/select/select-input/select-input';
import { useAppSearchParams } from '../../hooks';
import { CarHeader, carHeader, CarHeaderProps } from '../../../../api';

export interface SelectFilterProps
  extends BaseInputFilterProps<SelectOption, StringAppSearchParam | NumberAppSearchParam> {
  carHeaderOptions?: CarHeaderProps;
  staticOptions?: SelectOption[];
  filterDependencies?: AppSearchParams;
  disableAutoSelect?: boolean;
  disableSelectOnTab?: boolean;
  hideArrowIcon?: boolean;
}

export const SelectFilter = ({
  searchParamKey,
  labelTranslationKey,
  carHeaderOptions,
  staticOptions,
  filterDependencies,
  hideResetButton,
  defaultValue,
  disableAutoSelect = false,
  hasBackendDefault = false,
  hideArrowIcon,
  tabOnEnter,
  disabled,
  disableSelectOnTab,
}: SelectFilterProps) => {
  const [options, setOptions] = useState<SelectOption[] | undefined>(staticOptions);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const value = searchParams[searchParamKey];
  const selectedOption = useMemo(
    () =>
      options?.find((option) => option.value === value?.toString()) ||
      (defaultValue && options?.find((option) => option.value === defaultValue.value)) ||
      emptySelectOption,
    [defaultValue, options, value],
  );

  const onHandleReset = useCallback(() => {
    searchParams[searchParamKey] = undefined;
    setSearchParams(searchParams);
  }, [searchParamKey, searchParams, setSearchParams]);

  const onHandleChange = useCallback(
    (option: SelectOption) => {
      if (
        Object.keys(NumberAppSearchParam).find(
          (key) => NumberAppSearchParam[key as keyof typeof NumberAppSearchParam] === searchParamKey,
        )
      ) {
        searchParams[searchParamKey as NumberAppSearchParam] = Number(option.value);
      } else {
        searchParams[searchParamKey as StringAppSearchParam] = option.value;
      }
      setSearchParams(searchParams);
    },
    [searchParamKey, searchParams, setSearchParams],
  );

  const hasEmptyDependency = useCallback(() => {
    let isEmpty = false;
    for (const property in filterDependencies) {
      if (!filterDependencies[property as AppSearchParamKey]) {
        isEmpty = true;
      }
    }
    return isEmpty;
  }, [filterDependencies]);

  const mapHeaderValue = useCallback(
    (carHeaderOptions: CarHeaderProps, option: string | number) => {
      if (carHeaderOptions.type === CarHeader.MAKE || carHeaderOptions.type === CarHeader.MODEL) return String(option);
      if (carHeaderOptions.type === CarHeader.HP) {
        const result = Math.ceil((option as number) / 1.362) + t('KW ') + '/ ' + option + t('HP');
        if (carHeaderOptions.fuel?.startsWith('HYBRID')) return result + '*';
        return result;
      }
      return t(option.toString().toUpperCase(), option.toString());
    },
    [t],
  );

  useEffect(() => {
    if (staticOptions) {
      setOptions(staticOptions);
      return;
    }
    setOptions(undefined);
    if (carHeaderOptions && !hasEmptyDependency()) {
      setIsLoading(true);
      carHeader(carHeaderOptions)
        .then(({ headers }) => {
          // To reduce the amount of code and remove type checks,
          // we always use a toString and toUpperCase even tho it might not be necessary
          const translatedOptions = headers.map((option) => ({
            label: mapHeaderValue(carHeaderOptions, option),
            value: option.toString(),
          }));
          setOptions(translatedOptions);
        })
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, carHeaderOptions, staticOptions, hasEmptyDependency, filterDependencies, mapHeaderValue]);

  useEffect(() => {
    if (!isLoading && hasEmptyDependency() && value) {
      onHandleReset();
    }
  }, [hasEmptyDependency, onHandleReset, value, isLoading]);

  useEffect(() => {
    if (value && options) {
      const values = Object.values(options).map((o) => o.value);
      if (!isLoading && !values.includes(String(value)) && values.length) {
        onHandleReset();
      }
    }
  }, [value, onHandleReset, options, searchParamKey, isLoading]);

  useEffect(() => {
    if (options?.length === 1 && !hasEmptyDependency() && value !== options[0].value && !disableAutoSelect) {
      onHandleChange(options[0]);
    }
  }, [value, hasEmptyDependency, onHandleChange, options, disableAutoSelect]);

  useEffect(() => {
    if (
      !value &&
      defaultValue &&
      options?.find((option) => option.value === defaultValue.value && !hasBackendDefault)
    ) {
      onHandleChange(defaultValue);
    }
  }, [defaultValue, value, onHandleChange, options, hasBackendDefault]);

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onHandleReset}>
      <SelectInput
        hideArrowIcon={hideArrowIcon}
        disabled={disabled || hasEmptyDependency()}
        defaultValue={defaultValue || emptySelectOption}
        isLoading={isLoading}
        label={t(labelTranslationKey)}
        options={options || []}
        setValue={onHandleChange}
        value={selectedOption}
        tabOnEnter={tabOnEnter}
        disableSelectOnTab={disableSelectOnTab}
      />
    </InputWrapper>
  );
};
