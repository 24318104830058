import { Table, TableBody, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ResultTableRow } from './result-table-row';
import { CarTableCell } from './result-table-styles';
import { AppSearchParamKeys, useFilterGroup } from '@japieglobal/shared/src/components';
import { settings } from '@japieglobal/shared/src/settings';

interface ResultsTableProps {
  cars: CarResult[];
  reloadCarList: (id: number) => void;
}
export const ResultsTable = React.memo(({ cars, reloadCarList }: ResultsTableProps) => {
  useFilterGroup([
    AppSearchParamKeys.SORT_TURNOVER_EXT,
    AppSearchParamKeys.SORT_TURNOVER_INT,
    AppSearchParamKeys.SORT_APR,
    AppSearchParamKeys.SORT_CLICKS,
    AppSearchParamKeys.SORT_LEADS,
    AppSearchParamKeys.SORT_STOCK_DAYS,
    AppSearchParamKeys.SORT_STOCK_DAYS_CLIENT,
  ]);

  const theme = useTheme();
  const showReduced = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  const tableRows = useMemo(() => {
    return (
      <>
        {cars.map((car) => (
          <ResultTableRow showReduced={showReduced} key={car.id} car={car} reloadCarList={reloadCarList} />
        ))}
      </>
    );
  }, [cars, reloadCarList, showReduced]);

  const tableBody = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <TableBody name="car-results">{tableRows}</TableBody>;
  }, [tableRows]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CarTableCell>{t('PHOTO')}</CarTableCell>
            {!showReduced ? <CarTableCell>{t('BRAND_MODEL')}</CarTableCell> : undefined}
            {!showReduced ? <CarTableCell>{t('INFO')}</CarTableCell> : undefined}
            {!showReduced ? <CarTableCell>{t('ESTABLISHMENT')}</CarTableCell> : undefined}
            <CarTableCell>{settings().country == 'nl' ? t('LICENSE_PLATE') : t('REFERENCE_CODE')}</CarTableCell>
            <CarTableCell>{t('WINDOW')}</CarTableCell>
            <CarTableCell>{t('WEBSITE')}</CarTableCell>
            <CarTableCell />
            <CarTableCell />
            <CarTableCell />
            {!showReduced ? <CarTableCell>{t('PRICING')}</CarTableCell> : undefined}
            {!showReduced ? <CarTableCell /> : undefined}
            <CarTableCell />
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
    </TableContainer>
  );
});
