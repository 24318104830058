import React from 'react';
import { MenuButton } from '../../menu-button';
import { Publish } from '@mui/icons-material';
import { TopRowMenuButtonProps } from '../../top-row';
import { UserPermission } from '../../../../api';
import { hasPermission } from '../../../../utils';

export const FileValuationButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton
    hoverTitle={'FILE_VALUATION'}
    display={hasPermission(user, UserPermission.FILE_VALUATION)}
    internal={internal}
    link="/upload"
  >
    <Publish />
  </MenuButton>
);
