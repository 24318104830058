import React, { PropsWithChildren, useMemo } from 'react';
import { PageContainer } from '@japieglobal/shared/src/components';
import { SuperAdminPageFilter } from './super-admin-page-filter';

export enum PageType {
  MODEL_YEAR = 'model_year',
  REFRESH = 'refresh',
  CATALOG = 'catalog',
  CO2_MODEL_YEAR = 'co2_model_year',
  MANUAL_ADJUSTMENT = 'manual_adjustment',
  PLATE = 'plate',
  PLATES = 'plates',
  TRANSLATIONS = 'translations',
  CLUSTER_MANAGEMENT = 'cluster_management',
  OPTIONS_MANAGEMENT = 'options_management',
  MAP = 'customer map',
  CAR_ERRORS = 'car_errors',
  CLICKS_CONFIG = 'clicks_config',
}

export const SuperAdminPageContainer = ({ children }: PropsWithChildren) => {
  const leftComponent = useMemo(() => <SuperAdminPageFilter />, []);
  return <PageContainer internal leftMenuComponent={leftComponent} hideResetAllButton mainComponent={children} />;
};
