import React, { useEffect, useMemo, useState } from 'react';
import { AppSearchParamKeys, SelectFilter, Tooltip, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';
import { carHeader, CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api';
import { useTranslation } from 'react-i18next';

export const TaxationFilterHorsePower = () => {
  const [{ make, model, fuel, gear, body, build, hp }] = useAppSearchParams();
  const [hpNonElectric, setHpNonElectric] = useState<string | undefined>();
  const { t } = useTranslation();
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({
      dealer_specific: false,
      type: CarHeader.HP,
      make,
      model,
      fuel,
      gear,
      body,
      build,
    }),
    [make, model, fuel, gear, body, build],
  );
  const filterDependencies = useMemo(
    () => ({
      [AppSearchParamKeys.MAKE]: make,
      [AppSearchParamKeys.MODEL]: model,
      [AppSearchParamKeys.FUEL]: fuel,
      [AppSearchParamKeys.GEAR]: gear,
      [AppSearchParamKeys.BODY]: body,
      [AppSearchParamKeys.BUILD]: build,
    }),
    [body, fuel, gear, make, model, build],
  );

  useEffect(() => {
    let result: string | undefined = undefined;
    if (carHeaderOptions.fuel?.startsWith('HYBRID'))
      carHeader({ ...carHeaderOptions, type: CarHeader.HP_NON_ELECTRIC, hp }).then((r) => {
        if (r.headers.length) {
          const header = r.headers[0];
          const nonElectricType = carHeaderOptions.fuel == 'HYBRID' ? 'PETROL' : 'DIESEL';
          result =
            Math.ceil((header as number) / 1.362) + t('KW ') + '/ ' + header + t('HP') + ' ' + t(nonElectricType);
          setHpNonElectric(result);
        }
      });
    setHpNonElectric(result);
  }, [hp, carHeaderOptions, t]);
  return (
    <Grid2 size={2}>
      <Tooltip onHoverContent={hpNonElectric} placement={'top-start'}>
        <SelectFilter
          hideResetButton
          searchParamKey={AppSearchParamKeys.HP}
          carHeaderOptions={carHeaderOptions}
          filterDependencies={filterDependencies}
          labelTranslationKey="HP"
        />
      </Tooltip>
    </Grid2>
  );
};
