import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useContext, useMemo } from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { CarTableCell, ColoredButtonSmall } from '../result-table-styles';
import { Tooltip } from '@japieglobal/shared/src/components';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { getMoney, hasPermission } from '@japieglobal/shared/src/utils';
import { UserPermission } from '@japieglobal/shared/src/api';

interface ResultWindowButtonCell {
  car: CarResult;
}
export const ResultWindowButtonCell = React.memo(({ car }: ResultWindowButtonCell) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const carWindow = Number(searchParams.get('own_supply_car_id'));
  const { user } = useContext(UserContext);
  const setWindow = (value: number | undefined) => {
    setSearchParams((prev) => {
      if (value) prev.set('own_supply_car_id', String(value));
      else prev.delete('own_supply_car_id');
      return prev;
    });
  };
  const isActive = useMemo(() => carWindow === car.id, [car.id, carWindow]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CarTableCell>
      {car.stat?.own_supply_window_count !== undefined &&
      car.stat.own_supply_window_count > 1 &&
      car.sold?.since === 0 ? (
        <Tooltip onHoverContent={t('WINDOW_HINT')}>
          <ColoredButtonSmall
            className={`windowButton`}
            onClick={() => (isActive ? setWindow(undefined) : navigate(`/cars?own_supply_car_id=${car.id}`))}
          >
            ({car.stat.own_supply_window_count})
          </ColoredButtonSmall>
        </Tooltip>
      ) : (
        <></>
      )}
      {hasPermission(user, UserPermission.SHOW_PURCHASE_PRICE) && car.price?.purchase ? (
        <div>{getMoney(user, car.price?.purchase)}</div>
      ) : (
        <></>
      )}
    </CarTableCell>
  );
});
