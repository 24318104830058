import { ColoredButton } from '@japieglobal/shared/src/components';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function CarsLoadMoreButton({ handleGetMoreResults }: { handleGetMoreResults: () => void }) {
  const { t } = useTranslation();
  return (
    <Box style={{ marginTop: '40px', textAlign: 'center' }}>
      <ColoredButton style={{ width: 250 }} onClick={handleGetMoreResults}>
        {t('LOAD_MORE')}
      </ColoredButton>
    </Box>
  );
}
