import React from 'react';
import { styled } from '@mui/system';
import { ColoredOutlinedButton } from '@japieglobal/shared/src/components';

interface AdminMenuButtonProps {
  text: string;
  onClick: () => void;
  isActive: boolean;
  startIcon: JSX.Element;
}

// TODO: theme.palette is not typed here because of passing props. See https://github.com/mui/material-ui/issues/29875
const StyledButton = styled(ColoredOutlinedButton)<{ isactive: number }>(({ isactive, theme }) => ({
  'backgroundColor': isactive ? theme.palette.info.dark : undefined,
  'color': isactive ? theme.palette.info.contrastText : undefined,
  'justifyContent': 'left',
  '&: hover': {
    backgroundColor: isactive ? theme.palette.info.dark : undefined,
  },
}));

export const AdminMenuButton = ({ text, onClick, isActive, startIcon }: AdminMenuButtonProps) => (
  <StyledButton isactive={isActive ? 1 : 0} size="large" startIcon={startIcon} onClick={onClick} color="inherit">
    {text}
  </StyledButton>
);
