import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKeys, MultiSelectFilter, SelectOption } from '@japieglobal/shared/src/components';
import { CarStatus } from '@japieglobal/shared/src/types';

export const FilterStatus = () => {
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: CarStatus.STOCK, label: t('STOCK') },
    { value: CarStatus.SOLD, label: t('SOLD') },
    { value: CarStatus.HIDDEN, label: t('HIDDEN') },
    { value: CarStatus.PROPOSAL, label: t('PROPOSAL') },
  ];

  return (
    <span className="status">
      <MultiSelectFilter
        labelTranslationKey="STATUS"
        searchParamKey={AppSearchParamKeys.STATUSES}
        limitTags={5}
        staticOptions={options}
      />
    </span>
  );
};
