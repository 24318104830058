import { instance } from './instance';

export enum ClicksConfigKey {
  DEALER_NAME = 'dealer_name',
  PROPERTY_ID = 'property_id',
  REGEX = 'regex',
  CLICKS_THRESHOLD = 'clicks_threshold',
  CREDENTIALS = 'credentials',
  ID = 'id',
}

export interface ClicksConfig {
  [ClicksConfigKey.DEALER_NAME]: string;
  [ClicksConfigKey.PROPERTY_ID]: number;
  [ClicksConfigKey.REGEX]: string;
  [ClicksConfigKey.CLICKS_THRESHOLD]: number | null;
  [ClicksConfigKey.CREDENTIALS]: { [key: string]: string };
  [ClicksConfigKey.ID]: number;
}

export const getClicksConfigs = async (): Promise<ClicksConfig[]> =>
  instance.get(`extract/clicks_configs`).then((res) => res.data);

export const getClicksConfig = async (dealer_name: string): Promise<ClicksConfig[]> =>
  instance.get(`extract/clicks_config/${dealer_name}`).then((res) => res.data);

export const getGoogleUrls = async (dealer_name: string): Promise<string> =>
  instance.get(`extract/clicks_config/urls_from_google_api/${dealer_name}`).then((res) => res.data);

export const createClicksConfig = async (clicks_config: ClicksConfig): Promise<any> =>
  instance.post(`extract/clicks_config`, clicks_config).then((res) => res.data);

export const updateClicksConfig = async (dealer_name: string, property_id: string, clicks_config: ClicksConfig) =>
  instance
    .put(`extract/clicks_config/${dealer_name}?property_id=${property_id}`, clicks_config)
    .then((res) => res.data);

export const deleteClicksConfig = async (dealer_name: string, property_id: string) =>
  instance.delete(`extract/clicks_config/${dealer_name}?property_id=${property_id}`).then((res) => res.data);
