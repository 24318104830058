import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  ColoredButton,
  ConfirmationDialog,
  PageContainer,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks';
import { SavedTaxationsFilter } from './filter/taxations-filter';
import { TaxationsTable } from './taxations-table';
import { deleteSavedTaxation, exportSavedTaxation, getSavedTaxation, SavedTaxation } from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

export const Taxations = () => {
  const [appSearchParams, setAppSearchParams] = useAppSearchParams();
  const [taxations, setTaxations] = useState<SavedTaxation[]>([]);
  const [totalCars, setTotalCars] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  const { t } = useTranslation();

  const { page_index, order } = appSearchParams;
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const getTaxations = () => {
    setIsLoading(true);
    getSavedTaxation(user.dealer, appSearchParams)
      .then((res) => {
        setTaxations(res.results);
        setTotalCars(res.pageable.total);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteRow = () => {
    deleteSavedTaxation(user.dealer, rowsToDelete)
      .then(() => {
        snackbarSuccessMessage(t('REMOVE_TAXATION_SUCCESS'));
        setRowsToDelete([]);
        getTaxations();
      })
      .catch(snackbarErrorHandler)
      .finally(() => setOpenDialog(false));
  };

  const onDownload = useCallback(async () => {
    setIsLoading(true);
    await exportSavedTaxation(user.dealer, appSearchParams).finally(() => setIsLoading(false));
  }, [user.dealer, appSearchParams]);

  useEffect(() => {
    if (!isLoading) {
      setAppSearchParams({ page_index: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appSearchParams.make,
    appSearchParams.model,
    appSearchParams.license_plate,
    appSearchParams.user_email,
    appSearchParams.description,
    appSearchParams.order,
  ]);

  useEffect(() => {
    if (order) {
      const sort = order.includes('-') ? 'desc' : 'asc';
      const field = order.includes('-') ? order.split('-')[1] : order;
      setSortModel([{ sort: sort, field: field }]);
    } else {
      setSortModel([]);
    }
  }, [order]);

  useDebouncedEffect(getTaxations, 200, [user.dealer, appSearchParams]);

  return (
    <PageContainer
      internal
      mainComponent={
        <>
          {openDialog && (
            <ConfirmationDialog
              closeDialog={() => setOpenDialog(false)}
              title={t('REMOVE_TAXATION_TITLE')}
              question={t('REMOVE_TAXATION_QUESTION')}
              yesAction={handleDeleteRow}
              noAction={() => setOpenDialog(false)}
            />
          )}
          <TaxationsTable
            isLoading={isLoading}
            taxations={taxations}
            showRemove
            showTaxationLink
            totalCars={totalCars}
            pageIndex={page_index}
            sortModel={sortModel}
            setRowsToDelete={setRowsToDelete}
          />
        </>
      }
      leftMenuComponent={<SavedTaxationsFilter />}
      mainExtraButtons={[
        <IconButton disabled={rowsToDelete.length <= 0} onClick={() => setOpenDialog(true)}>
          <DeleteForeverIcon fontSize="large" color="primary" />
        </IconButton>,
        <ColoredButton disabled={isLoading} onClick={onDownload} color="primary" key={'download'}>
          {t('DOWNLOAD')}
        </ColoredButton>,
      ]}
    />
  );
};
