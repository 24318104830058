import { ClusterLocation } from '@japieglobal/shared/src/api';

export type CellRenderLogic = 'actions';
export interface clusterManagementColumn {
  field: keyof ClusterLocation;
  headerName: string;
  headerDescription: string;
  flex: number;
  cellRenderLogic?: CellRenderLogic;
}

export const clusterManagementColumns: clusterManagementColumn[] = [
  {
    field: 'location_name',
    headerName: 'Locations',
    headerDescription: 'Locations',
    flex: 3,
  },
  {
    field: 'name',
    headerName: 'Cluster',
    headerDescription: 'Cluster',
    flex: 3,
  },
  {
    field: 'id',
    headerName: 'Actions',
    headerDescription: 'Actions',
    flex: 3,
    cellRenderLogic: 'actions',
  },
];
