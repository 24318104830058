import React from 'react';
import { SelectFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api';

const carHeaderOptions: CarHeaderProps = { dealer_specific: false, type: CarHeader.MAKE };
export const SaveTaxationsFilterMake = () => (
  <SelectFilter
    tabOnEnter
    searchParamKey={AppSearchParamKeys.MAKE}
    carHeaderOptions={carHeaderOptions}
    labelTranslationKey="MAKE"
  />
);
