import { Link, styled } from '@mui/material';
import React from 'react';
import { useCarWindowStyles } from '../car-window-styles';
import { toggleThreeStates } from '@japieglobal/shared/src/components/inputs/toggle-input/utils';

const ClickLinkStyled = styled(Link)({
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2px 3px',
  borderRadius: '3px',
});

interface ClickLinkProps {
  children: React.ReactElement | string;
  value: boolean | undefined;
  setValue: (val: boolean | undefined) => void;
  triState: boolean;
}

export const ClickLink = ({ children, value, setValue, triState }: ClickLinkProps) => {
  const styles = useCarWindowStyles();
  const getClassName = () => {
    if (triState) return value === true ? styles.activeLink : value === false ? styles.all : '';
    return value === true ? styles.activeLink : '';
  };

  return (
    <ClickLinkStyled
      className={getClassName()}
      href="#"
      onClick={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        setValue(triState ? toggleThreeStates(value) : !value);
      }}
    >
      {children}
    </ClickLinkStyled>
  );
};
