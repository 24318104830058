import React, { useEffect, useState } from 'react';
import {
  AppSearchParamKeys,
  SelectFilter,
  useAppSearchParams,
  valuesToSelectOptions,
} from '@japieglobal/shared/src/components';
import { getRegions } from '@japieglobal/shared/src/api';

export const TaxationFilterRegion = () => {
  const [searchParams] = useAppSearchParams();
  const [regions, setRegions] = useState<string[]>([]);
  useEffect(() => {
    getRegions(searchParams).then((r) => setRegions(r));
  }, [searchParams]);
  if (!regions.length) return null;
  return (
    <SelectFilter
      disableAutoSelect
      searchParamKey={AppSearchParamKeys.TARGET_REGION}
      labelTranslationKey="TARGET_REGION"
      staticOptions={valuesToSelectOptions(regions)}
    />
  );
};
