import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { NumberInput, SelectInput, SelectOption, TextInput, ToggleInput } from '@japieglobal/shared/src/components';
import { Body, Fuel, Gear } from '@japieglobal/shared/src/types';
import { RdwPageContainer } from './rdw-page-container';
import { carHeader } from '@japieglobal/shared/src/api';
import { addRowToCatalog, CarHeader } from '@japieglobal/shared/src/api';
import { FormControl, FormHelperText } from '@mui/material';

export const RdwAddCarToCatalog = () => {
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [make, setMake] = useState<SelectOption | undefined>();
  const [model, setModel] = useState<SelectOption | undefined>();
  const [fuel, setFuel] = useState<SelectOption | undefined>();
  const [gear, setGear] = useState<SelectOption | undefined>();
  const [body, setBody] = useState<SelectOption | undefined>();
  const [equipmentFourDoors, setEquipmentFourDoors] = useState<boolean | undefined>();
  const [kw, setKw] = useState<number | undefined>();
  const [cc, setCc] = useState<number | undefined>();
  const [minBuild, setMinBuild] = useState<number | undefined>();
  const [maxBuild, setMaxBuild] = useState<number | undefined>();
  const [wheelbase, setWheelbase] = useState<number | undefined>();
  const [kwNonElectric, setKwNonElectric] = useState<number | undefined>();
  const [exampleUrl, setExampleUrl] = useState<string | undefined>();
  const [catalogModelRegex, setCatalogModelRegex] = useState<string | undefined>();
  const [isVerified, setVerified] = useState<boolean | undefined>(true);
  const [co2Wltp, setCo2Wltp] = useState<number | undefined>();
  const [co2Nedc, setCo2Nedc] = useState<number | undefined>();
  const [modelYear, setModelYear] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { snackbarSuccessMessage, snackbarErrorMessage } = useSnackbarErrorHandler();

  useEffect(() => {
    carHeader({ type: CarHeader.MAKE, dealer_specific: false }).then((data) => {
      setMakes(data.headers as string[]);
    });
  }, []);

  useEffect(() => {
    if (make) {
      carHeader({
        type: CarHeader.MODEL,
        dealer_specific: false,
        make: make.value,
      }).then((data) => setModels(data.headers as string[]));
    }
    setModel(undefined);
  }, [make]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (make && model && fuel && gear && body && kw && minBuild && maxBuild && wheelbase && exampleUrl) {
      setIsLoading(true);
      addRowToCatalog({
        make: make.value,
        model: model.value,
        fuel: fuel.value as Fuel,
        gear: gear.value as Gear,
        body: body.value as Body,
        equipmentFourDoors,
        kw,
        cc,
        minBuild,
        maxBuild,
        wheelbase,
        kwNonElectric,
        exampleUrl,
        isVerified,
        catalogModel: catalogModelRegex || undefined,
        modelYear,
        co2Nedc,
        co2Wltp,
      })
        .then(() => snackbarSuccessMessage('Car successfully added to catalog'))
        .catch((e) => {
          console.log(e);
          snackbarErrorMessage(e?.response?.data?.detail || e?.response?.data || e);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <RdwPageContainer headerTranslationKey="ADD_CAR_TO_RDW" isLoading={isLoading} onSubmit={onSubmit}>
      <SelectInput
        label={t('MAKE')}
        value={make}
        setValue={setMake}
        options={makes.map((option) => ({ label: option, value: option }))}
        required
      />
      <SelectInput
        label={t('MODEL')}
        freeSolo
        value={model}
        setValue={setModel}
        options={models.map((option) => ({ label: option, value: option }))}
        required
      />
      <SelectInput
        label={t('FUEL')}
        value={fuel}
        setValue={setFuel}
        options={Object.values(Fuel).map((fuel) => ({ label: t(fuel), value: fuel }))}
        required
      />
      <SelectInput
        label={t('GEAR')}
        value={gear}
        setValue={setGear}
        options={Object.values(Gear).map((gear) => ({ label: t(gear), value: gear }))}
        required
      />
      <SelectInput
        label={t('BODY')}
        value={body}
        setValue={setBody}
        options={Object.values(Body).map((body) => ({ label: t(body), value: body }))}
        required
      />
      <ToggleInput
        disableTripleState
        toggleState={equipmentFourDoors}
        setToggleState={setEquipmentFourDoors}
        label={t('FOUR_DOORS')}
      />
      <NumberInput label={t('KW')} value={kw} setValue={setKw} required />
      <NumberInput label={t('CC')} value={cc} setValue={setCc} />
      <NumberInput label={t('MINBUILD')} value={minBuild} setValue={setMinBuild} required />
      <NumberInput label={t('MAXBUILD')} value={maxBuild} setValue={setMaxBuild} required />
      <NumberInput label={t('WHEELBASE')} value={wheelbase} setValue={setWheelbase} required />
      <NumberInput label={t('KWNONELECTRIC')} value={kwNonElectric} setValue={setKwNonElectric} />
      <TextInput label={t('EXAMPLE_URL')} value={exampleUrl} setValue={setExampleUrl} required />
      <ToggleInput disableTripleState toggleState={isVerified} setToggleState={setVerified} label={t('VERIFIED')} />
      <FormControl>
        <FormHelperText>Required if new model is added</FormHelperText>
        <TextInput label={t('CATALOG_MODEL_REGEX')} value={catalogModelRegex} setValue={setCatalogModelRegex} />
      </FormControl>
      <FormControl>
        <FormHelperText>Required if no existing co2/model year exists for earlier build of the car</FormHelperText>
        <NumberInput label={t('CO2_WLTP')} value={co2Wltp} setValue={setCo2Wltp} />
        <NumberInput label={t('CO2_NEDC')} value={co2Nedc} setValue={setCo2Nedc} />
        <NumberInput label={t('MODEL_YEAR')} value={modelYear} setValue={setModelYear} />
      </FormControl>
    </RdwPageContainer>
  );
};
