import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ToggleFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const PriceProposalButton = () => (
  <ToggleFilter
    disableTripleState
    searchParamKey={AppSearchParamKeys.SHOW_PRICE_PROPOSAL_CARS}
    labelTranslationKey="PRICE_PROPOSALS"
    icon={<NotificationsIcon />}
    className="priceProposalButton"
  />
);
