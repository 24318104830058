import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useTranslation } from 'react-i18next';
import { getPlatesByCarType } from '@japieglobal/shared/src/api';
import { RdwPageContainer, StyledDiv } from './rdw-page-container';
import { TextInput } from '@japieglobal/shared/src/components';

export const RdwGetPlatesByCarType = () => {
  const [carTypeId, setCarTypeId] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<string[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const input = form.querySelector('input') as HTMLInputElement;

    if (input) {
      setIsLoading(true);

      getPlatesByCarType(carTypeId!)
        .then(setResponse)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <RdwPageContainer
      buttonTranslationKey="GET"
      headerTranslationKey="GET_PLATE"
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      <TextInput label={t('CAR_TYPE_ID')} value={carTypeId} setValue={setCarTypeId} required />
      {`${t('COUNT')}: ${response?.length}`}
      {response?.map((k) => <StyledDiv key={k}>{k}</StyledDiv>)}
    </RdwPageContainer>
  );
};
