import React from 'react';
import { TFunction } from 'i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import Box from '@mui/material/Box';
import { largeNumberFormatter } from '@japieglobal/shared/src/utils';
import { User } from '@japieglobal/shared/src/api';
import { getCurrencySymbolAndPosition } from '@japieglobal/shared/src/utils';

interface ResultInfoPopupContentProps {
  user: User;
  car: CarResult;
  apkDays: number | undefined;
  t: TFunction;
}
export const resultInfoPopupContent = ({ user, car, t, apkDays }: ResultInfoPopupContentProps) => {
  const result: Array<Array<any>> = [];

  if (car.price?.local) {
    result.push([
      t('PRICE', { currencySymbol: getCurrencySymbolAndPosition(user).symbol }),
      largeNumberFormatter(user, car.price.local),
    ]);
  }
  if (car.price?.catalog) {
    result.push([
      t('CATALOG_PRICE', { currencySymbol: getCurrencySymbolAndPosition(user).symbol }),
      largeNumberFormatter(user, car.price.catalog),
    ]);
  }

  result.push([t('BUILD_YEAR'), car.build]);

  if (car.mileage) {
    result.push([t('MILEAGE'), largeNumberFormatter(user, car.mileage)]);
  }

  result.push([t('FUEL'), t(car.fuel?.toUpperCase() as string)]);
  result.push([t('TRANSMISSION'), t(car.gear?.toUpperCase() as string)]);
  result.push([t('BODY'), t(car.body?.toUpperCase() as string)]);

  if (car.options) {
    result.push([
      t('OPTIONS'),
      car.options
        .split(',')
        .map((option) => (
          <Box sx={{ color: !car.extracted_options?.includes(option) ? 'warning.main' : '#000' }}>
            {t(option.toUpperCase(), option)}
          </Box>
        )),
    ]);
  }

  result.push([t('POWER'), car.hp]);
  if (car.stock_days_rdw) {
    result.push([`RDW ${t('STAND_TIME')} (${t('DAYS')})`, car.stock_days_rdw]);
  }
  if (car.stock_days) {
    result.push([`${t('INTERNET_STATUS')} (${t('DAYS')})`, car.stock_days]);
  }
  if (car.stock_days_location) {
    result.push([`${t('LOCATION')} ${t('STAND_TIME')} (${t('DAYS')})`, car.stock_days_location]);
  }
  if (apkDays) {
    result.push([
      `${t('APK')}  (${t('DAYS')})`,
      <span style={{ color: apkDays && apkDays <= 30 ? 'red' : '' }}>{apkDays}</span>,
    ]);
  }
  return result;
};
