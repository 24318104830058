import React from 'react';
import { MenuButton } from '../../menu-button';
import { Home } from '@mui/icons-material';
import { TopRowMenuButtonProps } from '../../top-row';
import { UserPermission } from '../../../../api';
import { hasPermission } from '../../../../utils';

export const HomeButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton
    hoverTitle={'MONITORING'}
    internal={internal}
    link="/cars"
    display={hasPermission(user, UserPermission.MONITORING)}
  >
    <Home />
  </MenuButton>
);
