import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { CsmReportKey } from '@japieglobal/shared/src/api';

const TableCellKey = ({ value }: { value: string }) => (
  <TableCell size="small" sx={{ border: 1 }}>
    {value}
  </TableCell>
);
const TableCellValue = ({ value }: { value: number | string }) => (
  <TableCell size="small" align="center" sx={{ border: 1 }}>
    {value}
  </TableCell>
);

interface HoverTableProps {
  hoverValues: { [key: string]: number | string };
}

export const HoverTable = ({ hoverValues }: HoverTableProps) => (
  <Table>
    <TableBody>
      {Object.entries(hoverValues).map(
        ([key, value]: [string, number | string]) =>
          key !== CsmReportKey.VALUE && (
            <TableRow key={key}>
              <TableCellKey value={key} />
              <TableCellValue value={value} />
            </TableRow>
          ),
      )}
    </TableBody>
  </Table>
);
