import React from 'react';
import { UserbackProvider } from '@userback/react';
import { User } from './api';
import { useTheme } from '@mui/material';

export const FeedbackWidget = ({ user }: { user: User | undefined }) => {
  const token = '31325|47039|qx5WlM2Z1xoHlUY24AxawJwpx';
  const theme = useTheme();
  if (!user || !token) return null;
  const redirectLength = `switch_to_dealer=${user.dealer}`.length;

  const onOpen = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('switch_to_dealer', user.dealer);
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
  };

  const onClose = () => {
    const urlLength = window.location.href.length;
    const urlWithoutDealerDirect = window.location.href.substr(0, urlLength - redirectLength);
    window.history.pushState('', document.title, urlWithoutDealerDirect);
  };

  return (
    <UserbackProvider
      token={token}
      options={{
        native_screenshot: true,
        on_open: onOpen,
        on_close: onClose,
        email: user.email,
        custom_data: { dealer: user.dealer },
        widget_settings: {
          form_settings: {
            general: { main_button_background_colour: theme.palette.primary.main },
          },
        },
      }}
    />
  );
};
