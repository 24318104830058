import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { TextInput } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { RdwPageContainer, StyledDiv } from './rdw-page-container';
import { refreshCarRdw } from '@japieglobal/shared/src/api';

export const RdwRefreshCar = () => {
  const [licensePlate, setLicensePlate] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<(string | number | boolean)[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const input = form.querySelector('input') as HTMLInputElement;

    if (input) {
      setIsLoading(true);
      const licensePlate = input.value;
      refreshCarRdw(licensePlate)
        .then(setResponse)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <RdwPageContainer
      buttonTranslationKey="REFRESH"
      headerTranslationKey="REFRESH_PLATE"
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      <TextInput label={t('LICENSE_PLATE')} value={licensePlate} setValue={setLicensePlate} required />
      {Object.entries(response || {}).map(([k, v]) => (
        <StyledDiv key={k}>
          {k}: {v}
        </StyledDiv>
      ))}
    </RdwPageContainer>
  );
};
