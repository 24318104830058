import { Button, Input } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { MuiTelInput } from 'mui-tel-input';
import { AuthLink } from './auth-styled-components';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from '../utils/validate-phone-number';
import { addPhoneNumber, resendSmsVerificationCode, verifySmsVerificationCode } from '@japieglobal/shared/src/api';

interface AuthTwoFactorComponentProps {
  successAction: () => void;
  hasPhoneNumber: boolean;
  setHasPhoneNumber: (v: boolean) => void;
}
export const AuthTwoFactorComponent = ({
  successAction,
  hasPhoneNumber,
  setHasPhoneNumber,
}: AuthTwoFactorComponentProps) => {
  const [code, setCode] = useState('');
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const { t } = useTranslation();
  const handleSubmitCode = () => {
    verifySmsVerificationCode(code)
      .then(() => {
        successAction();
      })
      .catch((error) => {
        setCode('');
        snackbarErrorHandler(error);
      });
  };

  const handleSubmitPhoneNumber = () => {
    addPhoneNumber(newPhoneNumber)
      .then(() => {
        setHasPhoneNumber(true);
        resendCode();
      })
      .catch(snackbarErrorHandler);
  };

  const resendCode = () => {
    resendSmsVerificationCode()
      .then(() => snackbarSuccessMessage(`new code sent!`))
      .catch(snackbarErrorHandler);
  };

  return (
    <>
      {!hasPhoneNumber ? (
        <>
          <MuiTelInput
            style={{ marginLeft: '10px' }}
            value={newPhoneNumber}
            onChange={(input) => setNewPhoneNumber(input)}
            defaultCountry="NL"
          />
          <Button disabled={!isValidPhoneNumber(newPhoneNumber)} onClick={handleSubmitPhoneNumber}>
            {t('SAVE_PHONE_NUMBER')}
          </Button>
        </>
      ) : (
        <>
          <div>
            Enter code
            <Input style={{ marginLeft: '5px' }} autoFocus value={code} onChange={(e) => setCode(e.target.value)} />
            <Button onClick={handleSubmitCode} type="submit">
              Submit
            </Button>
          </div>
          <Button onClick={resendCode}>Resend verification code</Button>
          <AuthLink to="/auth/forgot/phone">Telefoon nummer wijzigen?</AuthLink>
        </>
      )}
    </>
  );
};
