import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AppSearchParamKeys,
  BaseTable,
  ConfirmationDialog,
  TableColDef,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { clusterManagementColumns } from './columns';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { IconButton, Link } from '@mui/material';
import { PageType, SuperAdminPageContainer } from '../../super-admin-page-container';
import { deleteOption, getOptions, OptionListItem, UserPermission } from '@japieglobal/shared/src/api';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LinkIcon from '@mui/icons-material/Link';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { hasPermission } from '@japieglobal/shared/src/utils';

interface ActionsProps {
  handleDelete: () => void;
  id: number;
  readOnly: boolean;
}

const AddIcon = ({ readOnly }: { readOnly: boolean }) => (
  <IconButton
    disabled={readOnly}
    component={Link}
    href={`/super-admin/${PageType.OPTIONS_MANAGEMENT}/create`}
    aria-label="add"
  >
    <AddCircleIcon color={readOnly ? 'disabled' : 'primary'} fontSize="large" />
  </IconButton>
);
const LinkCarToStandardOptions = ({ readOnly }: { readOnly: boolean }) => (
  <IconButton
    disabled={readOnly}
    component={Link}
    href={`/super-admin/${PageType.OPTIONS_MANAGEMENT}/car-options-linking`}
    aria-label="add"
  >
    <DirectionsCarIcon color={readOnly ? 'disabled' : 'primary'} fontSize="large" />
    <LinkIcon color={readOnly ? 'disabled' : 'primary'} fontSize="large" />
    <SettingsApplicationsIcon color={readOnly ? 'disabled' : 'primary'} fontSize="large" />
  </IconButton>
);

const Actions = ({ handleDelete, id, readOnly }: ActionsProps) => (
  <>
    <IconButton
      disabled={readOnly}
      href={`/super-admin/${PageType.OPTIONS_MANAGEMENT}/edit/${id}`}
      LinkComponent={Link}
      color="primary"
    >
      <ContentCutIcon />
    </IconButton>
    <IconButton disabled={readOnly} color="warning" onClick={handleDelete}>
      <DeleteForeverIcon />
    </IconButton>
  </>
);

export const OptionsManagement = () => {
  const [options, setOptions] = useState<OptionListItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState<OptionListItem>();
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const { user } = useContext(UserContext);
  const readOnly = !hasPermission(user, UserPermission.SYSTEM_ADMIN);
  const [{ make, model }] = useAppSearchParams([AppSearchParamKeys.MAKE, AppSearchParamKeys.MODEL]);

  const handleGetOptions = useCallback(() => {
    setIsLoading(true);
    getOptions({ model, make })
      .then(setOptions)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [make, model, snackbarErrorHandler]);

  const handleDelete = (id: number, name: string) => {
    setIsLoading(true);
    deleteOption(id)
      .then(() => {
        handleGetOptions();
        snackbarSuccessMessage(`The option ${name} has been permanently delete 🥺`);
      })
      .catch(snackbarErrorHandler)
      .finally(() => {
        setIsLoading(false);
        setOptionToDelete(undefined);
      });
  };

  const closeDialog = () => {
    setOptionToDelete(undefined);
    setOpenDialog(false);
  };

  useEffect(() => {
    handleGetOptions();
  }, [handleGetOptions]);

  const columns: TableColDef<OptionListItem>[] = clusterManagementColumns.map((column) => ({
    ...column,
    headerAlign: 'left',
    align: 'left',
    type: 'string',
    valueGetter: (value: any) =>
      (column.field === 'makes' || column.field === 'models') && value ? value.join() : value,
    renderCell: (params) =>
      column.cellRenderLogic === 'actions' ? (
        <Actions
          handleDelete={() => {
            setOpenDialog(true);
            setOptionToDelete(params.row);
          }}
          readOnly={readOnly}
          id={params.value}
        />
      ) : undefined,
  }));

  return (
    <SuperAdminPageContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <LinkCarToStandardOptions readOnly={readOnly} />
        <AddIcon readOnly={readOnly} />
      </div>
      <BaseTable
        isLoading={isLoading}
        rows={options}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={55}
        rowHeight={35}
      />
      {openDialog && (
        <ConfirmationDialog
          yesAction={closeDialog}
          title="Gooi gewoon weg? 🤔"
          question={`Are you sure you want to delete ${optionToDelete?.name}? You can not wind back this action.`}
          closeDialog={closeDialog}
          noAction={() => {
            if (optionToDelete !== undefined) {
              handleDelete(optionToDelete.id, optionToDelete?.name);
            }
          }}
          textYes="No"
          textNo="Yes"
        />
      )}
    </SuperAdminPageContainer>
  );
};
