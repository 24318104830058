import { User, UserPermission, UserRole } from '../api/services';

export const hasPermission = (user: User, permissionToCheck: UserPermission) =>
  user.permissions.includes(permissionToCheck);

export const hasAnyPermission = (user: User, permissionsToCheck: UserPermission[]) =>
  permissionsToCheck.some((permission) => user.permissions.includes(permission));

export const hasAllPermissions = (user: User, permissionsToCheck: UserPermission[]) =>
  permissionsToCheck.every((permission) => user.permissions.includes(permission));

export const isAdmin = (user: User) => user.role === UserRole.ADMIN || user.role === UserRole.SUPER_ADMIN;

export const isSuperAdmin = (user: User) => user.role === UserRole.SUPER_ADMIN;

export const isScrapeUser = (user: User) => user.role === UserRole.SCRAPE_USER;
