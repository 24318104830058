import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { MultiSelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { carHeader } from '@japieglobal/shared/src/api';
import { CarHeader, StandardOption } from '@japieglobal/shared/src/api';

export const ModelMultiSelect = ({
  setValue,
  value,
  standardOption,
}: {
  value?: SelectOption[];
  setValue: (value: SelectOption[]) => void;
  standardOption: StandardOption;
}) => {
  const [models, setModels] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getModels = useCallback(() => {
    if (standardOption?.make) {
      setIsLoading(true);
      carHeader({
        type: CarHeader.MODEL,
        make: standardOption.make,
        fuel: standardOption.fuel ?? undefined,
        body: standardOption.body ?? undefined,
        hp: standardOption.hp ?? undefined,
        build: standardOption.build ?? undefined,
        dealer_specific: false,
      })
        .then((data) =>
          setModels(
            (data.headers as string[]).map((model) => ({
              label: model,
              value: model,
            })),
          ),
        )
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, standardOption]);

  useEffect(() => {
    getModels();
  }, [getModels]);

  return (
    <MultiSelectInput
      freeSolo
      isLoading={isLoading}
      disabled={!standardOption?.make}
      label={t('MODEL')}
      options={models}
      setValue={setValue}
      value={value}
    />
  );
};
