import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { MultiSelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { carHeader } from '@japieglobal/shared/src/api';
import { CarHeader } from '@japieglobal/shared/src/api';

export const MakeMultiSelect = ({
  setValue,
  value,
}: {
  value?: SelectOption[];
  setValue: (value: SelectOption[]) => void;
}) => {
  const [makes, setMakes] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getMakes = useCallback(() => {
    setIsLoading(true);
    carHeader({ type: CarHeader.MAKE, dealer_specific: false })
      .then((data) => setMakes((data.headers as string[]).map((make) => ({ label: make, value: make }))))
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [snackbarErrorHandler]);

  useEffect(() => {
    getMakes();
  }, [getMakes]);

  return (
    <MultiSelectInput
      freeSolo
      isLoading={isLoading}
      label={t('MAKE')}
      options={makes}
      setValue={setValue}
      value={value}
    />
  );
};
