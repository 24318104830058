import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CarCompetitorView } from '../../cars/car-window/car-competitor-view';
import { useClickQueryStates } from '../../states/click-states';
import { addCarBasedOnRank } from '../../utils/add-car-to-list-rank-based';
import { reorderList } from '../../utils/reorder-list';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks';
import { TaxationErrorView } from './taxation-error-view';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { noop } from '@japieglobal/shared/src/utils';
import { usePriceRankingStates } from '../../states/price-ranking';
import { useAppSearchParams } from '@japieglobal/shared/src/components';

interface TaxationListViewProps {
  windowResult?: CarWindowResult;
  evaluateCar: (pageIndex?: number, pricingTarget?: number) => void;
  clickDealer?: string;
}

export const TaxationListView = React.memo(({ windowResult, clickDealer, evaluateCar }: TaxationListViewProps) => {
  const { resetPriceRanking, setPriceDiscount, setPricingTarget } = usePriceRankingStates();
  const { getClicksFromCar, clicksAPIStyle } = useClickQueryStates();
  const [, setAppSearchParams] = useAppSearchParams([]);

  const [competitorCars, setCompetitorCars] = useState<CarResult[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (windowResult) {
      if (windowResult.car) {
        setCompetitorCars(addCarBasedOnRank(windowResult.car, windowResult.window, windowResult.pagable.page_size));
      }
    }
  }, [windowResult]);

  const reorder = useCallback(
    (source: number, target: number) => {
      const newTarget = competitorCars[target]?.rank?.counter;

      setCompetitorCars((prevState) => {
        return reorderList(prevState, source, target);
      });
      if (newTarget !== undefined) {
        setPriceDiscount(undefined);
        setPricingTarget(newTarget);
        evaluateCar(pageIndex, newTarget);
      }
    },
    [competitorCars, evaluateCar, pageIndex, setPricingTarget, setPriceDiscount],
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender.current) {
        evaluateCar(pageIndex);
      } else {
        isFirstRender.current = false;
      }
    },
    500,
    [clickDealer, pageIndex],
  );

  useEffect(() => {
    if (clicksAPIStyle) {
      setPageIndex(0);
    }
  }, [clicksAPIStyle, setPageIndex, clickDealer]);

  const removeRanking = () => {
    resetPriceRanking();
    setAppSearchParams({ pricing_target: undefined });
    evaluateCar();
  };

  const pageCount = windowResult?.pagable?.page_count ?? 0;

  const clicksPassedDown = useMemo(() => getClicksFromCar(windowResult?.car), [windowResult?.car, getClicksFromCar]);

  const carCompetitorView = CarCompetitorView({
    removeRanking: removeRanking,
    car: windowResult?.car,
    cars: competitorCars,
    reorder,
    currentPage: pageIndex,
    changePage: setPageIndex,
    pageCount,
    reloadCarList: noop, // TODO: fix when refactoring CarCompetitorView
    clicks: clicksPassedDown,
    taxationView: true,
    error: windowResult?.error,
  });

  return (
    <>
      {windowResult?.car && (
        <>
          <div>{carCompetitorView.rows}</div>
          {pageCount > 0 && <div>{carCompetitorView.pagination}</div>}
        </>
      )}
      {windowResult?.error && <TaxationErrorView windowResult={windowResult} />}
    </>
  );
});
