import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCarWindowStyles = makeStyles((theme: Theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    padding: '4px 3px',
  },
  missing: {
    color: theme.palette.warning.main,
  },
  absent: {
    color: '#d3d3cd',
  },
  enabled: {
    color: theme.palette.primary.main,
  },

  antiLock: {
    border: `1px solid ${theme.palette.warning.main}`,
  },
  all: {
    color: 'green',
  },
}));
