import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { CarWindowPageCounter } from '../../../cars/car-window/car-window-page-counter';
import { useNavigate } from 'react-router-dom';
import { DealerUsersContext } from '../../../states/dealer-users-context';
import { AppSearchParamKeys, LoadingWrapper, useAppSearchParams } from '@japieglobal/shared/src/components';
import { UserContext } from '@japieglobal/shared/src/user-context';
import {
  listBannedCars,
  unBanCar,
  BannedCarResult,
  BannedCarsResult,
  UserPermission,
} from '@japieglobal/shared/src/api';
import { CarScrapeAdminButton } from '../../../cars/car-scrape-admin-button';
import { hasPermission } from '@japieglobal/shared/src/utils';

const StyledDiv = styled(`div`)({
  minHeight: '20vh',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
  margin: '20px',
});

export const BannedCarsDialog = () => {
  const [bannedCarsResult, setBannedCarsResult] = useState<BannedCarsResult | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dealerUsers } = useContext(DealerUsersContext);

  const showTrashBin = searchParams[AppSearchParamKeys.SHOW_TRASH_BIN_CARS];
  const showTrashBinMemo = useMemo(() => showTrashBin === true, [showTrashBin]);

  const fetchBannedCars = useCallback(() => {
    listBannedCars({ page_size: 10, page_index: pageIndex })
      .then((cars) => {
        setBannedCarsResult(cars);
      })
      .finally(() => setIsLoading(false));
  }, [pageIndex]);

  useEffect(() => {
    if (showTrashBinMemo) {
      setIsLoading(true);
      fetchBannedCars();
    }
  }, [showTrashBinMemo, fetchBannedCars]);

  const handleUnBanCar = (carId: number) => {
    setIsLoading(true);
    unBanCar(carId).then(() => fetchBannedCars());
  };

  const openWindow = (bannedCar: BannedCarResult) => {
    if (bannedCar.window_car_id)
      navigate(
        `/cars?window_car_id=${bannedCar.window_car_id}&${AppSearchParamKeys.SHOW_BANNED_CAR}=true&${AppSearchParamKeys.SHOW_EXTRA_FILTERS}=true`,
      );
    else if (bannedCar.taxation_url)
      window.open(`${bannedCar.taxation_url}&${AppSearchParamKeys.SHOW_BANNED_CAR}=true`, '_blank');
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={() => setSearchParams({ [AppSearchParamKeys.SHOW_TRASH_BIN_CARS]: undefined })}
      open={showTrashBinMemo}
      disableEnforceFocus
    >
      <>
        <DialogTitle>{t('BANNED_CARS')}</DialogTitle>
        <DialogContent dividers>
          <LoadingWrapper isLoading={isLoading}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('PHOTO')}</TableCell>
                  <TableCell align="left">{t('LICENSE_PLATE')}</TableCell>
                  <TableCell align="left">{t('MODEL')}</TableCell>
                  <TableCell align="left">{t('BAN_REASON')}</TableCell>
                  <TableCell align="left">{t('DATE')}</TableCell>
                  <TableCell align="left">{t('USER')}</TableCell>
                  <TableCell align="left">{t('WEBSITE')}</TableCell>
                  <TableCell align="left">{t('WINDOW')}</TableCell>
                  <TableCell align="left">{t('UNBAN_CAR')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bannedCarsResult?.results.map((car) => (
                  <TableRow key={car.id}>
                    <TableCell>
                      <Badge>
                        <img width="90px" alt="car" src={car.image} />
                        <CarScrapeAdminButton carUrl={car.url} carDealer={car.dealer?.name} />
                      </Badge>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{car.license_plate}</TableCell>
                    <TableCell>
                      {car.make}-{car.model}
                    </TableCell>
                    <TableCell>{car.reason}</TableCell>
                    <TableCell>{new Date(car.ban_date).toLocaleString()}</TableCell>
                    <TableCell>{(dealerUsers.find((d) => d.id === car.ban_user_id) || {}).full_name}</TableCell>
                    <TableCell>
                      <a href={car.url} rel="noreferrer" target="_blank">
                        <img
                          alt=""
                          style={{
                            width: '19px',
                            height: '19px',
                            display: 'block',
                            padding: '0',
                            margin: '0 10px',
                          }}
                          src="/images/icon-website.svg"
                        />
                      </a>
                    </TableCell>
                    <TableCell>
                      <Button
                        style={{ fontSize: '20px' }}
                        disabled={
                          (!car.window_car_id && !car.taxation_url) ||
                          (!!car.window_car_id && !hasPermission(user, UserPermission.MONITORING))
                        }
                        onClick={() => openWindow(car)}
                      >
                        +
                      </Button>
                    </TableCell>
                    <TableCell style={{ cursor: 'pointer' }}>
                      <RestoreFromTrashIcon onClick={() => handleUnBanCar(car.id)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!bannedCarsResult?.results.length && <StyledDiv>{t('BANNED_CARS_EMPTY')}</StyledDiv>}
          </LoadingWrapper>
        </DialogContent>
        {bannedCarsResult && bannedCarsResult.pagable.page_count > 0 && (
          <CarWindowPageCounter
            changePage={(index) => setPageIndex(index)}
            pageCount={bannedCarsResult.pagable.page_count - 1}
            currentPage={bannedCarsResult.pagable.page_index}
          />
        )}
      </>
    </Dialog>
  );
};
