import React, { useCallback, useEffect, useState } from 'react';
import { BaseTable, SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { clusterManagementColumns } from './columns';
import { TableColDef, TruncatedText } from '@japieglobal/shared/src/components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { IconButton } from '@mui/material';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import {
  createClusterLocation,
  deleteClusterLocation,
  editClusterLocation,
  getClusterLocations,
  getDealerLocations,
} from '@japieglobal/shared/src/api';
import { ClusterLocation } from '@japieglobal/shared/src/api';

interface ActionsProps {
  handleDelete: () => void;
  handleEdit: () => void;
  startEdit: () => void;
  handleCreate?: () => void;
  isEditing: boolean;
}

const Actions = ({ handleCreate, handleDelete, handleEdit, startEdit, isEditing }: ActionsProps) => (
  <>
    {isEditing ? (
      <IconButton color="primary" onClick={handleCreate || handleEdit}>
        <ThumbUpAltIcon />
      </IconButton>
    ) : (
      <IconButton color="primary" onClick={startEdit}>
        <PlumbingIcon />
      </IconButton>
    )}
    {!handleCreate && (
      <IconButton color="warning" onClick={handleDelete}>
        <DeleteForeverIcon />
      </IconButton>
    )}
  </>
);

export const ClusterManagement = () => {
  const [clusterLocations, setClusterLocations] = useState<ClusterLocation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editableClusterLocation, setEditableClusterLocation] = useState<number>();

  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const [clusterOptions, setClusterOptions] = useState<SelectOption[]>([]);

  const [newCluster, setNewCluster] = useState<string>('');
  const [newLocation, setNewLocation] = useState<string>('');

  const [editingCluster, setEditingCluster] = useState<string>('');
  const [editingLocation, setEditingLocation] = useState<string>('');

  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const mapToSelectOption = (value: string): SelectOption => ({ value, label: value });

  const getUniqueCluster = (allClusterLocations: ClusterLocation[]) => {
    const uniqueClustersSet = new Set<string>();
    allClusterLocations.forEach((obj) => {
      uniqueClustersSet.add(obj.name);
    });
    return Array.from(uniqueClustersSet);
  };

  const handleGetClusterLocations = useCallback(() => {
    setIsLoading(true);
    getClusterLocations()
      .then(setClusterLocations)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [snackbarErrorHandler]);

  const handleDelete = (id: number) => {
    setIsLoading(true);
    deleteClusterLocation(id)
      .then(handleGetClusterLocations)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };

  const handleEdit = (id: number, location: string, cluster: string) => {
    setIsLoading(true);
    editClusterLocation(id, location, cluster)
      .then(handleGetClusterLocations)
      .catch(snackbarErrorHandler)
      .finally(() => {
        setIsLoading(false);
        setEditableClusterLocation(undefined);
      });
  };

  const handleCreate = () => {
    setIsLoading(true);
    createClusterLocation(newLocation, newCluster)
      .then(() => {
        handleGetClusterLocations();
        setNewLocation('');
        snackbarSuccessMessage('Cluster location has been added 👍');
      })
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const uniqueClusters = getUniqueCluster(clusterLocations);
    setClusterOptions(Array.from(uniqueClusters).map(mapToSelectOption));
  }, [clusterLocations]);

  useEffect(() => {
    handleGetClusterLocations();
    getDealerLocations()
      .then((locations) => setLocationOptions(locations.map(mapToSelectOption)))
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [handleGetClusterLocations, snackbarErrorHandler]);

  const columns: TableColDef<ClusterLocation>[] = clusterManagementColumns.map((column) => ({
    ...column,
    headerAlign: 'left',
    align: 'left',
    type: 'string',
    renderCell: (params) => (
      <>
        {column.cellRenderLogic === 'actions' && (
          <Actions
            handleEdit={() =>
              handleEdit(params.value, editingLocation || params.row.location_name, editingCluster || params.row.name)
            }
            startEdit={() => {
              setEditableClusterLocation(params.value);
              setEditingLocation(params.row.location_name);
              setEditingCluster(params.row.name);
            }}
            handleCreate={params.rowNode.type === 'pinnedRow' ? handleCreate : undefined}
            handleDelete={() => handleDelete(params.value)}
            isEditing={editableClusterLocation === params.value || params.rowNode.type === 'pinnedRow'}
          />
        )}
        {((editableClusterLocation === params.rowNode.id && !column.cellRenderLogic) ||
          (params.rowNode.type === 'pinnedRow' && !column.cellRenderLogic)) && (
          <SelectInput
            freeSolo={column.field === 'location_name' ? false : true}
            value={
              (column.field === 'location_name'
                ? params.rowNode.type === 'pinnedRow'
                  ? newLocation
                  : editingLocation
                : params.rowNode.type === 'pinnedRow'
                  ? newCluster
                  : editingCluster) as unknown as SelectOption
              // This casting is done because for some reason when this value is really sent as a SelectOption.
              // The cluster ends up in a infinite switching between states.
              // This is a temporary solution, because of higher other priorities. This will also cause a warning in the console.
            }
            setValue={(option) => {
              column.field === 'location_name'
                ? params.rowNode.type === 'pinnedRow'
                  ? setNewLocation(option.value)
                  : setEditingLocation(option.value)
                : params.rowNode.type === 'pinnedRow'
                  ? setNewCluster(option.value)
                  : setEditingCluster(option.value);
            }}
            options={column.field === 'location_name' ? locationOptions.concat(clusterOptions) : clusterOptions}
            {...{ onKeyDown: (e) => e.stopPropagation() }}
          />
        )}
        {editableClusterLocation !== params.rowNode.id && !column.cellRenderLogic && (
          <TruncatedText>{params.value}</TruncatedText>
        )}
      </>
    ),
  }));

  return (
    <SuperAdminPageContainer>
      <BaseTable
        isLoading={isLoading}
        rows={clusterLocations}
        pinnedRows={{
          top: [{ created: '', dealer_name: '', id: 0, location_name: '', modified: '', name: '' }],
          bottom: [],
        }}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={55}
        rowHeight={35}
      />
    </SuperAdminPageContainer>
  );
};
