import { MenuButton } from '../../menu-button';
import React from 'react';
import { Settings } from '@mui/icons-material';
import { TopRowMenuButtonProps } from '../../top-row';
import { User, UserPermission } from '../../../../api';
import { hasAnyPermission, isAdmin } from '../../../../utils';

export const hasRequiredSettingsPermissions = (user: User) =>
  isAdmin(user) || hasAnyPermission(user, [UserPermission.PROTOCOL, UserPermission.PURCHASE, UserPermission.APR]);

export const SettingsButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton hoverTitle={'SETTINGS'} display={hasRequiredSettingsPermissions(user)} internal={internal} link="/admin">
    <Settings />
  </MenuButton>
);
