const env = process.env.REACT_APP_ENV || '!!env!!';

export const settings = (region?: string) => {
  let showLicensePlate = false;
  let country;
  let fallbackLanguage;
  let mapLatLongZoom;
  let currencyCode = 'EUR';
  const setting = region ?? env;

  switch (setting) {
    case 'nl':
      country = 'nl';
      showLicensePlate = true;
      fallbackLanguage = 'nl';
      mapLatLongZoom = [52.1, 4.9, 8];
      break;
    case 'be':
      country = 'be';
      fallbackLanguage = 'en';
      mapLatLongZoom = [50.5, 4.7, 8];
      break;
    case 'de':
      country = 'de';
      fallbackLanguage = 'de';
      mapLatLongZoom = [51.7, 10, 6];
      break;
    case 'se':
      country = 'se';
      fallbackLanguage = 'en';
      mapLatLongZoom = [59, 12, 6];
      currencyCode = 'SEK';
      break;
    case 'dk':
      country = 'dk';
      fallbackLanguage = 'en';
      mapLatLongZoom = [55.1, 10, 7];
      currencyCode = 'DKK';
      break;
    case 'fr':
      country = 'fr';
      fallbackLanguage = 'fr';
      mapLatLongZoom = [55.1, 10, 7];
      break;
    case 'ch':
      country = 'ch';
      fallbackLanguage = 'de';
      mapLatLongZoom = [55.1, 10, 7];
      currencyCode = 'CHF';
      break;
    case 'es':
      country = 'es';
      fallbackLanguage = 'en';
      mapLatLongZoom = [55.1, 10, 7];
      break;

    default:
      throw Error('ENV not set');
  }
  return { country, showLicensePlate, fallbackLanguage, mapLatLongZoom, currencyCode };
};
