import React, { useCallback, useEffect, useMemo } from 'react';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { DateAppSearchParam } from '../../types/date-app-search-param';
import { useAppSearchParams } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';

export interface DateRangeFilterProps
  extends Omit<BaseInputFilterProps<[Moment | undefined, Moment | undefined], DateAppSearchParam>, 'searchParamKey'> {
  searchParamKey: [DateAppSearchParam, DateAppSearchParam];
}

export const DateRangeFilter = ({
  searchParamKey,
  hideResetButton,
  defaultValue,
  hasBackendDefault = true,
  labelTranslationKey,
}: DateRangeFilterProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();

  const value: [string | undefined, string | undefined] = useMemo(
    () => [searchParams[searchParamKey[0]], searchParams[searchParamKey[1]]],
    [searchParamKey, searchParams],
  );

  const onHandleReset = () => {
    searchParams[searchParamKey[0]] = undefined;
    searchParams[searchParamKey[1]] = undefined;
    setSearchParams(searchParams);
  };

  const onHandleChange = useCallback(
    (newValues: [Moment | null, Moment | null]) => {
      console.log(newValues);
      newValues.forEach((newValue, index) =>
        newValue?.isValid() ? (searchParams[searchParamKey[index]] = newValue?.format('yyyy-MM-DD')) : '',
      );
      setSearchParams(searchParams);
    },
    [searchParamKey, searchParams, setSearchParams],
  );

  useEffect(() => {
    if (!value && defaultValue !== undefined && !hasBackendDefault) {
      onHandleChange([defaultValue[0] || null, defaultValue[1] || null]);
    }
  }, [defaultValue, hasBackendDefault, onHandleChange, value]);

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onHandleReset}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        format="DD/MM/YYYY"
        value={[value[0] ? moment(value[0]) : null, value[1] ? moment(value[1]) : null]}
        onChange={(value) => onHandleChange([value[0], value[1]])}
        label={t(labelTranslationKey)}
        sx={{ width: '100%' }}
      />
    </InputWrapper>
  );
};
