import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleIcon from '@mui/icons-material/People';
import StorageIcon from '@mui/icons-material/Storage';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AdminMenuButton } from './admin-menu-button';
import { AdminPageType } from '../hooks/admin-query-state';
import { UserPermission } from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import {
  hasPermission,
  isAdmin as isAdminUser,
  isScrapeUser,
  isSuperAdmin as isSuperAdminUser,
} from '@japieglobal/shared/src/utils';

interface AdminMenuProps {
  setPageTypeProxy: (pageType: AdminPageType) => void;
  pageType: AdminPageType;
}
interface AdminMenuButtonWrapper {
  icon?: ReactElement;
  text: string;
  buttonPageType: AdminPageType;
}
export const AdminMenu = React.memo(({ setPageTypeProxy, pageType }: AdminMenuProps) => {
  const { user } = useContext(UserContext);

  const isSuperAdmin = isSuperAdminUser(user);
  const isAdmin = isAdminUser(user);
  const isScraperManager = isScrapeUser(user) && hasPermission(user, UserPermission.SCRAPE_MANAGEMENT);
  const hasUserActivityPermission = hasPermission(user, UserPermission.USER_ACTIVITY);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const AdminMenuButtonWrapper = ({ icon, text, buttonPageType }: AdminMenuButtonWrapper) => (
    <AdminMenuButton
      startIcon={icon ?? <PeopleIcon />}
      text={t(text)}
      onClick={() => {
        setPageTypeProxy(buttonPageType);
        if (buttonPageType === AdminPageType.GLOBAL_ACTIVITY_LIST) {
          navigate(window.location.pathname, { replace: true });
        }
      }}
      isActive={pageType === buttonPageType}
    />
  );

  return (
    <>
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<PeopleIcon />}
          text={t('SUPER_ADMINS')}
          buttonPageType={AdminPageType.SUPER_ADMINS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper icon={<PeopleIcon />} text={t('ADMINISTRATOR')} buttonPageType={AdminPageType.ADMINS} />
      )}
      {isAdmin && (
        <AdminMenuButtonWrapper icon={<PeopleIcon />} text={t('USERS')} buttonPageType={AdminPageType.USERS} />
      )}
      {isAdmin && (
        <AdminMenuButtonWrapper
          icon={<PeopleIcon />}
          text={t('SERVICE_ACCOUNTS')}
          buttonPageType={AdminPageType.SERVICE_ACCOUNTS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<PeopleIcon />}
          text={t('PURCHASE')}
          buttonPageType={AdminPageType.ACTIVITY_PURCHASE_VIEW}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<PeopleIcon />}
          text={t('AUCTIONS')}
          buttonPageType={AdminPageType.ACTIVITY_AUCTIONS_VIEW}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper icon={<PeopleIcon />} text={t('KEY_USERS')} buttonPageType={AdminPageType.KEY_USERS} />
      )}
      {(isSuperAdmin || isScraperManager) && (
        <AdminMenuButtonWrapper
          icon={<PeopleIcon />}
          text={t('SCRAPE_ADMIN_USERS')}
          buttonPageType={AdminPageType.SCRAPE_ADMIN_USERS}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<DirectionsCarIcon />}
          text={t('DEALERS')}
          buttonPageType={AdminPageType.DEALERS}
        />
      )}
      {hasUserActivityPermission && (
        <AdminMenuButtonWrapper
          icon={<StorageIcon />}
          text={t('GLOBAL_ACTIVITY_STATS')}
          buttonPageType={AdminPageType.GLOBAL_ACTIVITY_LIST}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<StorageIcon />}
          text={t('CSM_REPORT')}
          buttonPageType={AdminPageType.CSM_REPORT}
        />
      )}
      {isSuperAdmin && (
        <AdminMenuButtonWrapper
          icon={<StorageIcon />}
          text={t('SALES_REPORT')}
          buttonPageType={AdminPageType.SALES_REPORT}
        />
      )}
    </>
  );
});
