import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  getPermissions,
  Permission,
  PermissionCategory,
  User,
  UserPermission,
  UserRole,
} from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { MultiSelectInput, SelectOption } from '@japieglobal/shared/src/components';

interface AdminUserPermissionsProps {
  permissions: UserPermission[];
  setPermissions: (val: UserPermission[]) => void;
  isScrape: boolean;
  isServiceAccount: boolean;
  editUser: Partial<User>;
}

export const AdminUserPermissions = ({
  setPermissions,
  permissions,
  isScrape,
  isServiceAccount,
  editUser,
}: AdminUserPermissionsProps) => {
  const [allowedPermissions, setAllowedPermissions] = useState<Permission[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getPermissions().then((res) => {
      const filteredPermissions = res
        .filter((p) => user.permissions.includes(p.name))
        .filter(
          (p) =>
            !isServiceAccount ||
            p.category == PermissionCategory.API ||
            p.name == 'purchase' ||
            p.name.startsWith('internationalTrader'),
        )
        .filter((p) => !isScrape || p.category === 'SCRAPE_ADMIN')
        .filter((p) => p.category !== 'API' || editUser.role === UserRole.SUPER_ADMIN || editUser.is_service_account)
        .filter((p) => p.category !== 'SUPER_ADMIN' || editUser.role === UserRole.SUPER_ADMIN)
        .filter(
          (p) => p.category !== 'SCRAPE_ADMIN' || [UserRole.SCRAPE_USER, UserRole.SUPER_ADMIN].includes(editUser.role!),
        )
        .filter(
          (p) =>
            p.category != 'ADMIN' ||
            [UserRole.SUPER_ADMIN.toString(), UserRole.ADMIN.toString()].includes(editUser.role || UserRole.USER),
        );
      setAllowedPermissions(filteredPermissions);
    });
  }, [editUser.is_service_account, editUser.role, isScrape, isServiceAccount, user.permissions, user.role]);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setPermissions([...permissions, event.target.name as UserPermission]);
      } else {
        setPermissions(permissions.filter((f) => f !== event.target.name));
      }
    },
    [permissions, setPermissions],
  );

  const handleChangeSelect = useCallback((values: UserPermission[]) => setPermissions(values), [setPermissions]);

  const renderSwitch = (feature: SelectOption) => {
    return (
      <div key={feature.value}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={permissions.includes(feature.value as UserPermission) ?? false}
              onChange={handleChange}
              name={feature.value}
            />
          }
          label={feature.label}
        />
      </div>
    );
  };

  const renderMultiSelect = (permissionsToShow: SelectOption[], group: PermissionCategory, prefix: string = '') => {
    return (
      <MultiSelectInput
        options={permissionsToShow}
        setValue={(selectedUploadPermissions) => {
          const otherPermission = allowedPermissions
            .filter((p) => p.category != group && permissions.includes(p.name))
            .map((p) => p.name);
          const uploadPermission = selectedUploadPermissions.map((p) => p.value);
          handleChangeSelect(otherPermission.concat(uploadPermission as UserPermission[]));
        }}
        value={allowedPermissions
          .filter((p) => p.category == group && permissions.includes(p.name))
          .map((p) => mapPermissionToSelectOption(p, prefix))}
      />
    );
  };
  const mapPermissionToSelectOption = (permission: Permission, prefix: string) => ({
    value: permission.name,
    label: t(permission.name.replaceAll(prefix, '').toUpperCase()),
  });

  const permissionGroup = (group: PermissionCategory, width: number, showAsDropdown: boolean = false, prefix = '') => {
    const permissionsToShow = allowedPermissions
      .filter((p) => p.category === group)
      .map((p) => mapPermissionToSelectOption(p, prefix));
    if (!permissionsToShow.length) return null;

    return (
      <div
        style={{
          display: 'flex',
          width: `${width}%`,
          flexDirection: 'column',
        }}
      >
        <span>
          <b>{t(group)}</b>
        </span>
        {showAsDropdown ? renderMultiSelect(permissionsToShow, group, prefix) : permissionsToShow.map(renderSwitch)}
      </div>
    );
  };
  const secondRowWidth = editUser.role != UserRole.SUPER_ADMIN ? 50 : 33;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '18px' }}>
        <strong>{t('ACCESS_TO')}</strong>
      </div>
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.MONITORING, 50)}
        {permissionGroup(PermissionCategory.TAXATION, 50)}
      </div>

      <br />
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.PROTOCOL, secondRowWidth)}
        {permissionGroup(PermissionCategory.OTHER, secondRowWidth)}
        {permissionGroup(PermissionCategory.API, secondRowWidth)}
      </div>
      <br />
      {permissionGroup(PermissionCategory.UPLOAD, 100, true, 'upload_')}
      <br />
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.ADMIN, 33)}
        {permissionGroup(PermissionCategory.SUPER_ADMIN, 33)}
        {permissionGroup(PermissionCategory.SCRAPE_ADMIN, 33)}
      </div>
    </div>
  );
};
