import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ToggleFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const HiddenCarsButton = () => (
  <ToggleFilter
    disableTripleState
    searchParamKey={AppSearchParamKeys.SHOW_HIDDEN_CARS}
    labelTranslationKey="SHOW_HIDDEN"
    icon={<VisibilityOffIcon />}
    className={'hiddenCarsButton'}
  />
);
