import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from '@japieglobal/shared/src/components';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { UserActivityWithId, buildActivitiesStats, buildUsersStats } from '../utils/admin-activity-list-mapper';
import { useTimeSpan } from '../shared/period-select';
import { AdminMainActivityListSwitchedRowCol } from './admin-main-activity-list-switched-row-col';
import { AdminMainUserActivityList } from './admin-main-user-activity-list';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminQueryStateKey } from '../hooks/admin-query-state';
import { DownloadIcon } from '../shared/icons';
import { getGlobalActivity, userStats, userStatsAllDownload } from '@japieglobal/shared/src/api';

export const AdminMainGlobalActivityList = () => {
  const [activity, setActivity] = useState<UserActivityWithId[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [users, setUsers] = useState<{ [id: string]: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { selectPeriod, dateFrom, dateTo } = useTimeSpan();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [activityType, setActivityType] = useQueryString<string>(AdminQueryStateKey.ACTIVITY_TYPE, '');

  useEffect(() => {
    if (dateFrom) {
      setIsLoading(true);
      if (activityType) {
        userStats({
          activities: [activityType],
          date_from: dateFrom,
          date_to: dateTo,
          show_super_admins: false,
        })
          .then((res) => {
            const { rows, stats } = buildUsersStats(res);
            setUsers(rows);
            setColumns(rows.map((r) => r.label));
            return stats;
          })
          .then(setActivity)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoading(false));
      } else {
        getGlobalActivity({ date_from: dateFrom, date_to: dateTo })
          .then((res) => {
            const { rows, stats } = buildActivitiesStats(res);
            setColumns(rows);
            return stats;
          })
          .then(setActivity)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoading(false));
      }
    }
  }, [snackbarErrorHandler, dateFrom, dateTo, activityType]);

  const onClickDownload = useCallback(() => {
    userStatsAllDownload({
      date_from: dateFrom,
      date_to: dateTo,
      activities: [activityType],
      is_service_account: false,
      show_super_admins: false,
    }).catch(snackbarErrorHandler);
  }, [dateFrom, dateTo, activityType, snackbarErrorHandler]);

  return (
    <AdminMainWrapper
      title={`${t('ACTIVITY_LIST')}${activityType ? `: ${t(activityType)}` : ''}`}
      titleRightComponent={
        <div>
          {activityType && <DownloadIcon onClick={onClickDownload} />}
          {selectPeriod}
        </div>
      }
    >
      <LoadingWrapper isLoading={isLoading}>
        {activityType && (
          <AdminMainUserActivityList
            activityType={activityType}
            userActivity={activity}
            columns={columns}
            users={users}
          />
        )}
        {!activityType && (
          <AdminMainActivityListSwitchedRowCol
            userActivity={activity}
            columns={columns}
            onClickRow={activityType ? undefined : setActivityType}
          />
        )}
      </LoadingWrapper>
    </AdminMainWrapper>
  );
};
