import { AppSearchParamKeys, NumberFilter } from '@japieglobal/shared/src/components';
import React from 'react';

export const TaxationFilterStartPrice = () => (
  <NumberFilter
    min={0}
    max={1000000}
    labelTranslationKey="START_PRICE"
    searchParamKey={AppSearchParamKeys.START_PRICE}
  />
);
