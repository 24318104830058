import React, { useCallback, useEffect, useState } from 'react';
import {
  AppSearchParamKeys,
  MultiSelectInput,
  SelectOption,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StandardOptionsEditCreate } from './standard-options-edit-create';
import { getStandardOptions, StandardOption } from '@japieglobal/shared/src/api';

export const StandardOptions = ({ optionMake }: { optionMake: string | undefined }) => {
  const { optionId } = useParams();
  const BASE_NEW_STANDARD_OPTION: StandardOption = {
    id: 0,
    option_id: Number(optionId),
    make: optionMake || '',
    model: null,
    fuel: null,
    body: null,
    hp: null,
    build: null,
    wheelbase: null,
  };

  const [standardOptions, setStandardOptions] = useState<StandardOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStandardOption, setSelectedStandardOption] = useState<StandardOption>();
  const [newStandardOption, setNewStandardOption] = useState<StandardOption>(BASE_NEW_STANDARD_OPTION);
  const { t } = useTranslation();

  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [{ make, model }] = useAppSearchParams([AppSearchParamKeys.MAKE, AppSearchParamKeys.MODEL]);

  const standardOptionToSelectOption = ({
    id,
    make,
    model,
    fuel,
    body,
    hp,
    build,
    wheelbase,
  }: StandardOption): SelectOption => ({
    value: String(id),
    label: `${make}, ${model}, ${fuel ? t(fuel) : null}, ${body ? t(body.toUpperCase()) : null}, ${hp}, ${build}, ${wheelbase}`,
  });

  const handleGetStandardOptions = useCallback(() => {
    setIsLoading(true);
    getStandardOptions({ option_id: Number(optionId), model, make })
      .then((value) => {
        setStandardOptions(
          value.map((item) => {
            item.make = item.make.toUpperCase();
            return item;
          }),
        );
      })
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [make, model, optionId, snackbarErrorHandler]);

  useEffect(() => {
    handleGetStandardOptions();
  }, [handleGetStandardOptions]);

  return (
    <Box display={'flex'} flexDirection={'row'} columnGap={'10px'}>
      <Box display={'flex'} flex={1}>
        <MultiSelectInput
          label="Edit a standard option"
          isLoading={isLoading}
          options={standardOptions.map(standardOptionToSelectOption)}
          setValue={(newSelectedStandardOption) => {
            if (newSelectedStandardOption.length > 0) {
              setNewStandardOption(BASE_NEW_STANDARD_OPTION);
              setSelectedStandardOption(
                standardOptions.find(
                  (standardOption) => standardOption.id === Number(newSelectedStandardOption[0].value),
                ),
              );
            } else {
              setSelectedStandardOption(undefined);
            }
          }}
          value={selectedStandardOption ? [standardOptionToSelectOption(selectedStandardOption)] : undefined}
          maxSelectedOptions={1}
        />
      </Box>
      <StandardOptionsEditCreate
        isLoading={isLoading}
        newStandardOption={newStandardOption}
        selectedStandardOption={selectedStandardOption}
        setIsLoading={setIsLoading}
        getStandardOptions={handleGetStandardOptions}
        setNewStandardOption={setNewStandardOption}
        setSelectedStandardOption={setSelectedStandardOption}
        BASE_NEW_STANDARD_OPTION={BASE_NEW_STANDARD_OPTION}
      />
    </Box>
  );
};
