import { Tooltip } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { Dialog, Theme } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { CarScrapeAdminButton } from '../../car-scrape-admin-button';
import { CarTableCell } from '../result-table-styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    noPadding: {
      padding: 0,
    },
  }),
);

const StyledImage = styled(`img`)({
  width: '100%',
  cursor: 'pointer',
});
const StyledImageBig = styled(`img`)({
  width: '430px',
});

const ColorSpan = styled('span')({
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '5px',
  height: '5px',
  backgroundColor: 'black',
});

export const ResultTableImageCell = React.memo(
  function ResultTableImageCell(props: { car: CarResult; windowView: boolean | undefined }) {
    const { car, windowView } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    function showOriginal(car) {
      return car.body_original && car.body_original !== car.body;
    }

    return (
      <CarTableCell className={windowView ? classes.noPadding : undefined}>
        <Tooltip
          onHoverContent={
            <>
              <StyledImageBig width="430px" alt="car large" src={car.image} />
              {showOriginal(car) && (
                <div style={{ fontSize: 12 }}>
                  Original Body: {t(car.body_original?.toUpperCase() as string)}, Overruled Body:{' '}
                  {t(car.body?.toUpperCase() as string)}
                </div>
              )}
            </>
          }
        >
          <div style={{ position: 'relative', width: 90 }}>
            <StyledImage
              alt="car"
              src={`${car.image}?`}
              onClick={() => setModalOpen(true)}
              className={windowView ? classes.noPadding : undefined}
            />
            {showOriginal(car) ? <ColorSpan /> : undefined}
            <CarScrapeAdminButton carDealer={car.dealer?.name} country={car.country} carUrl={car.url} />
          </div>
        </Tooltip>
        <Dialog disableEnforceFocus onClose={() => setModalOpen(false)} open={modalOpen}>
          <StyledImageBig width="430px" alt="car large" src={car.image} />
        </Dialog>
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
