import { useDebouncedEffect, useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getTiny } from '@japieglobal/shared/src/api';

export function TinyUrlPage() {
  const navigate = useNavigate();
  const { key } = useParams();
  const { snackbarErrorMessage } = useSnackbarErrorHandler();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useDebouncedEffect(
    () => {
      if (!key) return;
      getTiny(key)
        .then((data) => {
          const url = new URL(data.url);
          Array.from(searchParams.entries()).forEach(([k, v]) => {
            url.searchParams.set(k, v);
          });
          window.location.href = url.href;
        })
        .catch(() => {
          snackbarErrorMessage(t('TINY_URL_NOT_FOUND'));
          navigate('/');
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [],
  );

  return <></>;
}
