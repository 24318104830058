import React, { useState } from 'react';
import { Grid2 } from '@mui/material';
import { TaxationStep } from './taxation-step';
import { ColoredBorderButton, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarHeader } from '@japieglobal/shared/src/api';

export const TAXATION_STEP_ORDER: CarHeader[] = [
  CarHeader.MAKE,
  CarHeader.MODEL,
  CarHeader.FUEL,
  CarHeader.GEAR,
  CarHeader.BODY,
  CarHeader.BUILD,
  CarHeader.HP,
  CarHeader.OPTIONS,
];

export const TaxationStepsView = () => {
  // The use of this component is for now on hold.
  // We might use this in a later stage.
  const [currentStepCount, setCurrentStepCount] = useState(0);
  const [searchParams] = useAppSearchParams();
  const currentSearchParamKey = TAXATION_STEP_ORDER[currentStepCount];
  const goToNextStep = () => setCurrentStepCount(currentStepCount + 1);
  const goToPreviousStep = () => setCurrentStepCount(currentStepCount - 1);

  return (
    <Grid2 container rowSpacing={1}>
      <Grid2 container justifyContent={'space-between'}>
        {currentStepCount !== 0 && <ColoredBorderButton onClick={goToPreviousStep}>Back</ColoredBorderButton>}
        {currentStepCount !== TAXATION_STEP_ORDER.length - 1 ? (
          <ColoredBorderButton onClick={goToNextStep} disabled={!searchParams[currentSearchParamKey]}>
            Next
          </ColoredBorderButton>
        ) : (
          <ColoredBorderButton onClick={goToNextStep}>Valuate</ColoredBorderButton>
        )}
      </Grid2>
      <TaxationStep goToNextStep={goToNextStep} inputKey={currentSearchParamKey} />
    </Grid2>
  );
};
