import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { NumberInput, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Body, Fuel, Gear } from '@japieglobal/shared/src/types';
import { RdwPageContainer } from './rdw-page-container';
import { addModelYear, getCo2ModelYear } from '@japieglobal/shared/src/api';
import { TaxationFilterMake } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-make';
import { TaxationFilterModel } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-model';
import { TaxationFilterFuel } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-fuel-select';
import { TaxationFilterGear } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-transmission-select';
import { TaxationFilterBody } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-body-select';
import { TaxationFilterBuildYear } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-build-year';
import { TaxationFilterHorsePower } from '../../../taxatie/taxation-top-view/top-side-filters/taxation-filter-horsepower';

export const RdwAddModelYear = () => {
  const [modelYear, setModelYear] = useState<number | undefined>();
  const [minModelYear, setMinModelYear] = useState<number | undefined>();
  const [maxModelYear, setMaxModelYear] = useState<number | undefined>();
  const [co2Wltp, setCo2Wltp] = useState<number | undefined>();
  const [co2Nedc, setCo2Nedc] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { snackbarSuccessMessage, snackbarErrorHandler } = useSnackbarErrorHandler();
  const [{ make, model, fuel, gear, body, hp, build }] = useAppSearchParams();

  useEffect(() => {
    if (make && model && fuel && gear && body && hp && build) {
      getCo2ModelYear({
        make: make,
        model: model,
        fuel: fuel as Fuel,
        gear: gear as Gear,
        body: body as Body,
        hp: Number(hp),
        build: Number(build),
      }).then((response) => {
        setModelYear(response.model_year);
        setMinModelYear(response.min_model_year);
        setMaxModelYear(response.max_model_year);
        setCo2Nedc(response.co2_nedc);
        setCo2Wltp(response.co2_wltp);
      });
    }
  }, [body, build, fuel, gear, hp, make, model]);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (make && model && fuel && gear && body && hp && build && modelYear && minModelYear && maxModelYear) {
      setIsLoading(true);
      addModelYear({
        make: make,
        model: model,
        fuel: fuel as Fuel,
        gear: gear as Gear,
        body: body as Body,
        hp: Number(hp),
        build: Number(build),
        modelYear,
        minModelYear,
        maxModelYear,
        co2Wltp,
        co2Nedc,
      })
        .then(() => snackbarSuccessMessage('Model and co2 successfully added!'))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <RdwPageContainer headerTranslationKey="ADD_MODEL_YEAR" isLoading={isLoading} onSubmit={onSubmit}>
      <TaxationFilterMake />
      <TaxationFilterModel />
      <TaxationFilterFuel />
      <TaxationFilterGear />
      <TaxationFilterBody />
      <TaxationFilterBuildYear />
      <TaxationFilterHorsePower />
      <NumberInput label={t('MODEL_YEAR')} value={modelYear} setValue={setModelYear} required />
      <NumberInput label={t('MIN_MODEL_YEAR')} value={minModelYear} setValue={setMinModelYear} required />
      <NumberInput label={t('MAX_MODEL_YEAR')} value={maxModelYear} setValue={setMaxModelYear} required />
      <NumberInput label={t('CO2_WLTP')} value={co2Wltp} setValue={setCo2Wltp} />
      <NumberInput label={t('CO2_NEDC')} value={co2Nedc} setValue={setCo2Nedc} />
    </RdwPageContainer>
  );
};
