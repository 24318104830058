import { User } from '../api';
import { settings } from '../settings';

export const getCurrencySymbolAndPosition = (user: User) => {
  const formatter = new Intl.NumberFormat(`${user.language}-${settings().country.toUpperCase()}`, {
    style: 'currency',
    currency: settings().currencyCode,
    currencyDisplay: 'narrowSymbol', // Use narrow symbols where available
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatted = formatter.format(0); // Format the number 0
  const parts = formatter.formatToParts(0); // Break into parts

  const symbol: string = parts.find((part) => part.type === 'currency')?.value || '';
  const position: 'start' | 'end' = formatted.startsWith(symbol) ? 'start' : 'end';

  return { symbol, position };
};
