import React, { useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { SelectOption } from '../inputs';
import { DROPPABLE_LEFTOVERS, DROPPABLE_SELECTED, DroppableContainer, DroppableId } from './droppable-container';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ColoredOutlinedButton } from '../colored-button';
import { useTranslation } from 'react-i18next';
import { noop } from '../../utils';
import { deleteExportColumns, getExportColumns, setExportColumns } from '../../api';
import { ExportColumn, getExportColumnsResponse } from '../../api';

export interface ColumnsSelectorProps {
  language: string;
  type: ExportColumn;
  leftColumnTitle?: string;
  rightColumnTitle?: string;
  initialCollapseState?: boolean;
  collapsibleButtonTitle?: string;
  onChange?: (selectedItems: string[]) => void;
}

export const ItemSelector = ({
  language,
  type,
  initialCollapseState,
  leftColumnTitle,
  rightColumnTitle,
  collapsibleButtonTitle,
  onChange = noop,
}: ColumnsSelectorProps) => {
  const [availableItems, setAvailableItems] = useState<SelectOption[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectOption[]>([]);
  const [open, setOpen] = React.useState(initialCollapseState);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(!open);
  };

  const mapAllColumns = (items: getExportColumnsResponse) => {
    const allColumnsCopy: SelectOption[] = [];
    for (const key in items.all_columns) {
      !items.selected_columns.includes(key) && allColumnsCopy?.push({ value: key, label: items.all_columns[key] });
    }
    setAvailableItems(allColumnsCopy);
  };

  const mapAllSelectedColumns = (items: getExportColumnsResponse) => {
    setSelectedItems(
      items.selected_columns.map((selectedColumn) => ({
        value: selectedColumn,
        label: items.all_columns[selectedColumn],
      })),
    );
  };

  const handleSetExportColumns = (items: SelectOption[]) => {
    setExportColumns(
      items.map((column) => column.value),
      language,
      type,
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getExportColumns(language, type)
      .then((items) => {
        mapAllColumns(items);
        mapAllSelectedColumns(items);
      })
      .finally(() => setIsLoading(false));
  }, [language, type]);

  const onHandleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const movedColumn = selectedItems.find((column) => column.value === result.draggableId);
    const newIndex = result.destination.index;
    const oldIndex = result.source.index;
    const selectedColumnsCopy = [...selectedItems];
    const allColumnsCopy = [...availableItems];
    // Dropped in selected list
    if (result.destination.droppableId === DROPPABLE_SELECTED) {
      // New item
      if (!movedColumn) {
        selectedColumnsCopy.splice(
          newIndex,
          0,
          allColumnsCopy.find((column) => column.value === result.draggableId) as SelectOption,
        );
        allColumnsCopy.splice(
          allColumnsCopy.findIndex((column) => column.value === result.draggableId),
          1,
        );
      }
      // Already in the list, just changing position
      else {
        selectedColumnsCopy.splice(oldIndex, 1);
        selectedColumnsCopy.splice(newIndex, 0, movedColumn);
      }
    }
    // Putting back in leftover list
    if (result.destination.droppableId === DROPPABLE_LEFTOVERS && movedColumn) {
      selectedColumnsCopy.splice(oldIndex, 1);
      allColumnsCopy.splice(newIndex, 0, movedColumn);
    }
    setSelectedItems(selectedColumnsCopy);
    setAvailableItems(allColumnsCopy);
    handleSetExportColumns(selectedColumnsCopy);
    onChange(selectedColumnsCopy.map((column) => column.value));
  };

  const onSetDefaultSelection = () => {
    setIsLoading(true);
    deleteExportColumns(language, type)
      .then((items) => {
        mapAllColumns(items);
        mapAllSelectedColumns(items);
        onChange(items.selected_columns);
      })
      .finally(() => setIsLoading(false));
  };

  const onHandleSelectAll = (droppableId: DroppableId) => {
    const items = availableItems.concat(selectedItems);
    if (droppableId === 'droppable_leftovers') {
      setSelectedItems(items);
      onChange(items.map((item) => item.value));
      setAvailableItems([]);
      handleSetExportColumns(items);
    } else {
      setSelectedItems([]);
      onChange([]);

      setAvailableItems(items);
      handleSetExportColumns([]);
    }
  };

  const ColumnsView = () => (
    <DragDropContext onDragEnd={onHandleDragEnd}>
      <Box flexGrow={1} display={'flex'} flexDirection={'row'} columnGap={'16px'}>
        <DroppableContainer
          isLoading={isLoading}
          onSelectAll={onHandleSelectAll}
          selectAllText={t('SELECT_ALL')}
          droppableId={'droppable_leftovers'}
          items={availableItems}
          title={leftColumnTitle || t('AVAILABLE_COLUMNS')}
          onSetDefault={onSetDefaultSelection}
        />
        <DroppableContainer
          isLoading={isLoading}
          onSelectAll={onHandleSelectAll}
          selectAllText={t('DESELECT_ALL')}
          droppableId={'droppable_selected'}
          items={selectedItems}
          title={rightColumnTitle || t('SELECTED_COLUMNS')}
        />
      </Box>
    </DragDropContext>
  );
  return (
    <div style={{ fontSize: '0.8rem' }}>
      {initialCollapseState !== undefined && (
        <div style={{ margin: '12px 0px' }}>
          <ColoredOutlinedButton sx={{ fontSize: '0.8rem' }} onClick={handleClick}>
            {collapsibleButtonTitle ?? t('CHANGE_COLUMN_PREFERENCE')} {open ? <ExpandLess /> : <ExpandMore />}
          </ColoredOutlinedButton>
        </div>
      )}
      <Collapse in={open ?? true} timeout="auto" unmountOnExit>
        <ColumnsView />
      </Collapse>
    </div>
  );
};
