import React from 'react';
import { AppSearchParamKeys, ToggleFilter } from '@japieglobal/shared/src/components';
import { Equipment } from '@japieglobal/shared/src/types';

const getImageUrl = (key: keyof Equipment) => {
  return `/images/icons/options/enabled/icon-${key}.png`;
};

export const FilterOptionToggles = React.memo(() => (
  <>
    <ToggleFilter
      searchParamKey={AppSearchParamKeys.EQUIPMENT_FOUR_DOORS}
      labelTranslationKey="FOUR_DOORS"
      icon={getImageUrl('four_doors')}
    />
  </>
));
