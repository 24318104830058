import { User, UserPermission } from '@japieglobal/shared/src/api';
import { AdminPageType } from '../hooks/admin-query-state';
import { hasPermission, isAdmin, isScrapeUser, isSuperAdmin } from '@japieglobal/shared/src/utils';

const NON_ADMIN_PAGES_WITHOUT_ACCESS_REQUIREMENT = [
  AdminPageType.USERS,
  AdminPageType.EDIT_USER,
  AdminPageType.ADD_USER,
];

export const isUserAllowedToSeePage = ({ user, pageType }: { user: User; pageType: AdminPageType }) => {
  const hasFeatureActivityChart = hasPermission(user, UserPermission.USER_ACTIVITY);

  const displayPage =
    NON_ADMIN_PAGES_WITHOUT_ACCESS_REQUIREMENT.includes(pageType) ||
    (hasFeatureActivityChart && pageType === AdminPageType.ACTIVITY_LIST) ||
    (isAdmin(user) && AdminPageType.SERVICE_ACCOUNTS) ||
    isSuperAdmin(user) ||
    (isScrapeUser(user) && hasPermission(user, UserPermission.SCRAPE_MANAGEMENT));

  return displayPage;
};
