import 'core-js/features/string/repeat';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import './wdyr';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { i18next, TranslationFileType } from '../../shared/src/i18n/i18n';
import { AppContainer } from './app-container';
import './index.css';

i18next(TranslationFileType.FRONTEND);

LicenseInfo.setLicenseKey(
  '9fcb81d90a533ae490a940258f31c565Tz05NTY4OSxFPTE3NTQ1NzgzMTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

Sentry.init({
  dsn: 'https://f554e8c24a2222dc8f176e9667fc46fb@o4505521379606528.ingest.sentry.io/4505793663270912',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/api\..+\.jpcars\.dev\//, /^https:\/\/api\..+\.jp\.cars\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

const element = document.getElementById('root')!;
const root = ReactDOM.createRoot(element);

root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
);
