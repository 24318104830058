import React, { useMemo } from 'react';
import {
  SelectInput,
  SelectOption,
  valuesToSelectOptions,
  valueToSelectOption,
} from '@japieglobal/shared/src/components';
import {
  CsmReport,
  CsmReportKey,
  SalesReportRow,
  SuperAdminResponseType,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

interface CsmSelectCellProps {
  row: CsmReport | SalesReportRow;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const CsmSelectCell = ({ setRowsIsLoading, onDealerUpdated, row, superAdmins }: CsmSelectCellProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const options = useMemo(
    () => valuesToSelectOptions(superAdmins.map((superAdmin) => superAdmin.full_name || superAdmin.email)),
    [superAdmins],
  );

  const handleSelect = (selectedOptions: SelectOption) => {
    setRowsIsLoading(true);
    updateDealerAccount({
      name: row[CsmReportKey.DEALER],
      health: row[CsmReportKey.HEALTH],
      status: row[CsmReportKey.STATUS],
      [CsmReportKey.CSM]: selectedOptions.value,
    })
      .then((res) => onDealerUpdated(res))
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
  };

  return <SelectInput value={valueToSelectOption(row[CsmReportKey.CSM])} setValue={handleSelect} options={options} />;
};
