import { UserActivity, UserActivityList, UserStatsResponse } from '@japieglobal/shared/src/api';
import { formatDateAsDate, formatDateAsHourAndMinute, formatDateAsMonthAndDay } from '@japieglobal/shared/src/utils';

export interface UserActivityWithId {
  id: string;
  LAST_ACTIVITY: string | undefined;
  TOTAL: number | undefined;
  date: string;
  [key: string]: string | number | undefined;
}
const summarizeActivity = (activity: UserActivity) => {
  return Object.entries(activity)
    .filter(([k]) => k !== 'LAST_ACTIVITY')
    .map(([, v]) => v)
    .reduce((k, v) => k + v, 0);
};

export const mapAdminUserActivity = (userActivity: UserActivityList) => {
  return Object.entries(userActivity).map(([key, entry]) => {
    const date = new Date(key);
    return {
      ...entry,
      id: key,
      date: formatDateAsDate(date),
      label: formatDateAsMonthAndDay(date),
      LAST_ACTIVITY: entry.LAST_ACTIVITY ? formatDateAsHourAndMinute(new Date(entry.LAST_ACTIVITY)) : undefined,
      TOTAL: summarizeActivity(entry) || undefined,
    };
  });
};

export const mapAdminUserStats = (userStats: UserStatsResponse) => {
  return Object.entries(userStats).map(([key, entry]) => {
    const date = new Date(key);
    const values = Object.fromEntries(entry.map((row) => [row.email || row.name, row.totalActivities]));
    return {
      ...values,
      id: key,
      date: formatDateAsDate(date),
      label: formatDateAsMonthAndDay(date),
      LAST_ACTIVITY: undefined,
      TOTAL: Object.values(values).reduce((k, v) => k + v, 0) || undefined,
    };
  });
};

export const findUniqueActivities = (userActivity: UserActivityList) => {
  const uniqueActivities = new Set<string>();
  uniqueActivities.add('LAST_ACTIVITY');
  Object.values(userActivity)
    .flatMap((v) => Object.keys(v))
    .forEach((v) => {
      uniqueActivities.add(v);
    });
  uniqueActivities.add('TOTAL');
  return Array.from(uniqueActivities);
};

export const findUniqueUsers = (userStats: UserStatsResponse) => {
  const uniqueActivities = {};
  Object.values(userStats)
    .flatMap((v) => Object.values(v))
    .forEach((v) => {
      uniqueActivities[v.id] = v.email || v.name;
    });
  uniqueActivities['TOTAL'] = 'TOTAL';
  return uniqueActivities;
};

export const sortRows = (rows: string[], stats: UserActivityWithId[]) => {
  const totalRow = stats[stats.length - 1];
  return rows.sort((a: string, b: string) => {
    if (a === 'TOTAL') return 1;
    return ((totalRow[b] as number) || 0) - ((totalRow[a] as number) || 0);
  });
};

export const addTotalColumn = (rows: string[], stats: UserActivityWithId[]) => {
  const totals = {};
  rows.forEach((u) => {
    stats.forEach((row: UserActivityWithId) => {
      totals[u] = (totals[u] || 0) + (row[u] || 0);
    });
  });
  stats.push({
    ...(totals as UserActivityWithId),
    label: 'TOTAL',
  });
  return stats;
};

export const buildActivitiesStats = (userActivity: UserActivityList) => {
  const uniqueActivities = findUniqueActivities(userActivity);
  const stats = addTotalColumn(uniqueActivities.slice(1), mapAdminUserActivity(userActivity));
  const rows = [uniqueActivities[0]].concat(sortRows(uniqueActivities.slice(1), stats));
  return { stats, rows };
};

export const buildUsersStats = (userStats: UserStatsResponse) => {
  const uniqueUsers = findUniqueUsers(userStats);
  const stats = addTotalColumn(Object.values(uniqueUsers), mapAdminUserStats(userStats));
  const userLabels = Object.fromEntries(Object.keys(uniqueUsers).map((u) => [uniqueUsers[u], u]));
  const sortedLabels = sortRows(Object.keys(userLabels), stats);
  return { stats, rows: sortedLabels.map((label) => ({ id: userLabels[label], label })) };
};
