import React from 'react';
import { NumberAppSearchParam, NumberFilter } from '@japieglobal/shared/src/components';

export const TaxationFilterPricingTarget = () => (
  <NumberFilter
    labelTranslationKey={NumberAppSearchParam.PRICING_TARGET}
    isMoneyInput
    searchParamKey={NumberAppSearchParam.PRICING_TARGET}
  />
);
