import { carMessages } from '../api';
import { CarMessageContextType } from './car-message-types';

export class CarMessageService {
  private static instance: CarMessageService;

  static get INSTANCE() {
    if (!this.instance) {
      this.instance = new CarMessageService();
    }
    return this.instance;
  }

  getMessages = (carIds: number[]) =>
    carMessages(carIds)
      .then((res) => {
        if (res) {
          const result: CarMessageContextType = {};
          result.carMessagesByCarId = res;
          result.unreadCount = Object.values(res).filter((messages) => messages.some((c) => !c.read)).length;

          return result;
        }
        return undefined;
      })
      .catch(() => {
        return undefined;
      });
}
