import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKeys, ColoredButton, useAppSearchParams } from '@japieglobal/shared/src/components';

const ButtonStyled = styled(ColoredButton)({
  width: '100%',
});


export const FilterMoreButton = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useAppSearchParams([AppSearchParamKeys.SHOW_EXTRA_FILTERS]);

  return (
    <>
      <div>
        {!searchParams.show_extra_filters && (
          <ButtonStyled
            onClick={() => {
              searchParams.show_extra_filters = true;
              setSearchParams(searchParams);
            }}
          >
            {t('MORE_FILTERS')}
          </ButtonStyled>
        )}
        {searchParams.show_extra_filters && (
          <ButtonStyled
            onClick={() => {
              searchParams.show_extra_filters = undefined;
              setSearchParams(searchParams);
            }}
          >
            {t('HIDE_FILTERS')}
          </ButtonStyled>
        )}
      </div>
    </>
  );
};
