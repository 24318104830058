import { AppSearchParamKeys, ToggleFilter } from '@japieglobal/shared/src/components';
import React from 'react';

export const IsCommercialToggleFilter = () => (
  <ToggleFilter
    labelTranslationKey="IS_COMMERCIAL"
    searchParamKey={AppSearchParamKeys.IS_COMMERCIAL}
    defaultValue={undefined}
  />
);
