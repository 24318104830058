import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminMenu } from './menu/admin-menu';
import { AdminMain } from './admin-main';
import { AdminPageType, useAdminQueryState } from './hooks/admin-query-state';
import { isUserAllowedToSeePage } from './utils/admin-user-allowed-to-see-page';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { PageContainer } from '@japieglobal/shared/src/components';

interface AdminMainViewProps {
  pageType: AdminPageType;
}
export const AdminMainView = ({ pageType }: AdminMainViewProps) => {
  const { adminQueryState } = useAdminQueryState();
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const setPageTypeProxy = useCallback(
    (newPageType: AdminPageType) => {
      if (pageType !== newPageType) {
        navigate(`/admin/${newPageType}`);
      }
    },
    [navigate, pageType],
  );

  useEffect(() => {
    if (!isUserAllowedToSeePage({ pageType, user })) {
      setPageTypeProxy(AdminPageType.USERS);
    }
  }, [pageType, setPageTypeProxy, user]);

  const leftMenuComponent = useMemo(
    () => <AdminMenu setPageTypeProxy={setPageTypeProxy} pageType={pageType} />,
    [pageType, setPageTypeProxy],
  );
  const mainComponent = useMemo(
    () => <AdminMain pageType={pageType} adminQueryState={adminQueryState} />,
    [adminQueryState, pageType],
  );

  return (
    <PageContainer internal hideResetAllButton leftMenuComponent={leftMenuComponent} mainComponent={mainComponent} />
  );
};
