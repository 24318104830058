import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { NetworkError } from '../../../../shared/src/api/network-error';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { settings } from '@japieglobal/shared/src/settings';
import { FilterLocation } from '../../filter/filter-location';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { useSearchParams } from 'react-router-dom';
import { valuateFile } from '@japieglobal/shared/src/api';
import { isSuperAdmin } from '@japieglobal/shared/src/utils';

const useStyles = makeStyles(() => ({
  main: {
    padding: 20,
    margin: 20,
  },
  table: {
    width: 400,
  },
  container: {
    color: '#616161',
  },
  dropMessage: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dropImage: {
    display: 'block',
  },
  formControl: {
    minWidth: 150,
    paddingTop: 5,
  },
}));
const Wrapper = styled(`div`)({ marginTop: '30px' });

export const ValuateFileView = React.memo(function ValuateFileView() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const { snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const templates = settings().country === 'nl' ? ['default', 'default_international'] : ['default_international'];
  const { user } = useContext(UserContext);
  const [templateValuation, setTemplateValuation] = useState<string>(templates[0]);
  const [useStandardOptions, setUseStandardOptions] = useState<boolean>(true);
  const [usePaidOptions, setUsePaidOptions] = useState<boolean>(false);
  const [enableOtherCountries, setEnableOtherCountries] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoading(true);
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      valuateFile(formData, {
        template: templateValuation,
        use_standard_options: useStandardOptions,
        use_paid_options: usePaidOptions,
        location_name: searchParams.get('location_names'),
        enable_other_countries: enableOtherCountries,
        send_email: sendEmail,
      })
        .then(async (res) => {
          const blob = await res.data;
          const a = document.createElement('a'); // TODO: See downloadfile function
          a.href = URL.createObjectURL(blob);
          a.download = 'valuate.xlsx';
          a.click();
          setLoading(false);
          snackbarSuccessMessage(t('FILE_VALUATION_SUCCESS'));
        })
        .catch((err: NetworkError) => {
          snackbarErrorMessage(
            err.response?.status === 429 ? t('FILE_VALUATION_QUOTA_ERROR') : t('FILE_VALUATION_ERROR'),
          );
          setLoading(false);
        });
    },
    [
      templateValuation,
      useStandardOptions,
      usePaidOptions,
      searchParams,
      enableOtherCountries,
      sendEmail,
      snackbarSuccessMessage,
      t,
      snackbarErrorMessage,
    ],
  );
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#96BF31',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Wrapper>
      <Grid2 container spacing={3}>
        <Grid2 className={classes.container} size={9}>
          <div {...getRootProps({ style })} className={classes.main}>
            <input {...getInputProps()} />
            <div className={classes.dropMessage}>
              <img src="/dragdrop.png" width={160} alt="drag-n-drop" />
              <div>{t('FILE_VALUATION_DROP_MESSAGE')}</div>
            </div>
          </div>
        </Grid2>
        <Grid2 className={classes.container} size={3}>
          {settings().country === 'nl' && (
            <FormControl margin="normal" className={classes.formControl} fullWidth>
              <InputLabel>{t('Template')}</InputLabel>
              <Select
                value={templateValuation}
                onChange={(e) => setTemplateValuation(e.target.value as string)}
                name="template"
              >
                {templates.map((template) => (
                  <MenuItem key={template} value={template}>
                    {t(`FILE_VALUATION_TEMPLATE_${template.toUpperCase()}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {isSuperAdmin(user) && (
            <FormControl margin="normal" className={classes.formControl} fullWidth>
              <FilterLocation />
            </FormControl>
          )}
          <Button href={`/valuation-input/${templateValuation}.xlsx`} startIcon={<GetAppIcon />}>
            {t('FILE_VALUATION_INPUT_EXAMPLE')}
          </Button>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={useStandardOptions}
                onChange={() => setUseStandardOptions(!useStandardOptions)}
                name={'useStandardOptions'}
              />
            }
            label={t('USE_STANDARD_OPTIONS')}
          />
          {user.permissions.some((p) => p.startsWith('internationalTrader')) && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={enableOtherCountries}
                  onChange={() => setEnableOtherCountries(!enableOtherCountries)}
                  name={'enableOtherCountries'}
                />
              }
              label={t('VALUATE_OTHER_COUNTRIES')}
            />
          )}
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={sendEmail}
                onChange={() => setSendEmail(!sendEmail)}
                name={t('SEND_EMAIL')}
              />
            }
            label={t('SEND_EMAIL')}
          />
          {isSuperAdmin(user) && settings().country == 'nl' && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={usePaidOptions}
                  onChange={() => setUsePaidOptions(!usePaidOptions)}
                  name={'usePaidOptions'}
                />
              }
              label={t('USE_PAID_OPTIONS')}
            />
          )}
          {loading && <CircularProgress />}
        </Grid2>
      </Grid2>
    </Wrapper>
  );
});
