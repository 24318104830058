import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { OptionSelectProps } from './type';
import { CarHeader, carHeader } from '@japieglobal/shared/src/api';

export const HpSelect = ({ setValue, value, standardOption }: OptionSelectProps) => {
  const [hps, setHps] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getHps = useCallback(() => {
    if (standardOption?.make) {
      setIsLoading(true);
      carHeader({
        type: CarHeader.HP,
        make: standardOption.make,
        fuel: standardOption.fuel ?? undefined,
        body: standardOption.body ?? undefined,
        build: standardOption.build ?? undefined,
        dealer_specific: false,
      })
        .then((data) => setHps((data.headers as number[]).map((hp) => ({ label: String(hp), value: String(hp) }))))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [standardOption, snackbarErrorHandler]);

  useEffect(() => {
    getHps();
  }, [getHps]);

  return (
    <SelectInput
      isLoading={isLoading}
      disabled={!standardOption?.make}
      label={t('HP')}
      options={hps}
      setValue={setValue}
      value={value}
    />
  );
};
