import { Box, styled } from '@mui/material';
import React, { useContext } from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { formatDecimalAsPercent } from '../../../utils/number-format';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { ResultTableAdditionalInformation } from '../../../taxation-and-results-shared/result-table-additional-information-cell/result-table-additional-information-cell';
import { ResultTableTrace } from '../../../taxation-and-results-shared/buttons/result-table-trace-button';
import { getMoney } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';

interface ResultPriceInformationProps {
  car: CarResult;
}

const BoxFlexRowWithFontSize = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '11px',
});

export const ResultPriceInformation = React.memo(
  ({ car }: ResultPriceInformationProps) => {
    const { user } = useContext(UserContext);
    return (
      <CarTableCell width={140}>
        <BoxFlexColumn>
          <BoxFlexRowWithFontSize>
            <span style={{ marginRight: '5px' }}>P:</span>
            <span>{getMoney(user, car.price?.local, undefined, 1)}</span>
          </BoxFlexRowWithFontSize>
          <BoxFlexRowWithFontSize>
            <span style={{ marginRight: '5px' }}>D:</span>
            <span>{formatDecimalAsPercent(car.rank?.target_perc)}</span>
          </BoxFlexRowWithFontSize>
          <Box flexDirection="row" display="flex-start" justifyContent="center">
            <ResultTableAdditionalInformation car={car} />
            <ResultTableTrace car={car} />
          </Box>
        </BoxFlexColumn>
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
