import { AppSearchParamKeys, NumberFilter } from '@japieglobal/shared/src/components';
import React from 'react';

export const TaxationFilterExtraMargin = () => {
  return (
    <NumberFilter
      endAdornment={'%'}
      min={0}
      max={100}
      labelTranslationKey="EXTRA_MARGIN"
      searchParamKey={AppSearchParamKeys.EXTRA_MARGIN}
      hasBackendDefault
    />
  );
};
