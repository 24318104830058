import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SuperAdminPageContainer } from '../../../super-admin-page-container';
import {
  CarOptionsAndStandardOptionsResult,
  createStandardOption,
  deleteStandardOption,
  getCarOptionsAndStandardOptions,
} from '@japieglobal/shared/src/api';
import { LoadingWrapper, SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { Grid2, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { StandardOptionsSelects } from './standard-options-selects';

export const LinkCarToStandardOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [selectValues, setSelectValues] = useState<{
    make?: string;
    model?: string;
    body?: string;
    fuel?: string;
    build?: number;
    hp?: number;
    wheelbase?: number;
  }>({});
  const [optionsAndStandardOptions, setOptionsAndStandardOptions] = useState<CarOptionsAndStandardOptionsResult>();
  const { t } = useTranslation();
  const { make, model, fuel, body, hp, build, wheelbase } = selectValues;

  const filteredOptions: SelectOption[] = useMemo(
    () =>
      optionsAndStandardOptions?.options
        .filter((o) => !optionsAndStandardOptions.standard_options.find((so) => so.id === o.id))
        .map((on) => ({ value: String(on.id), label: t(on.name) })) || [],
    [optionsAndStandardOptions?.options, optionsAndStandardOptions?.standard_options, t],
  );

  const getOptions = useCallback(() => {
    if (make && model && fuel && body && hp && build) {
      setIsLoading(true);
      getCarOptionsAndStandardOptions({ make, model, fuel, body, hp, build })
        .then(setOptionsAndStandardOptions)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [body, build, fuel, hp, make, model, snackbarErrorHandler]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  const addStandardOption = (selectOption: SelectOption) => {
    if (make) {
      setIsLoading(true);
      createStandardOption({
        id: 0,
        option_id: Number(selectOption.value),
        make: make,
        model: model ?? null,
        body: body ?? null,
        build: build ?? null,
        fuel: fuel ?? null,
        hp: hp ?? null,
        wheelbase: wheelbase ?? null,
      })
        .then(() => {
          getOptions();
          snackbarSuccessMessage('Standard Option has been added :)');
        })
        .catch((e) => {
          snackbarErrorHandler(e);
          setIsLoading(false);
        });
    }
  };

  const removeStandardOption = (id: number) => {
    setIsLoading(true);
    deleteStandardOption(id)
      .then(() => {
        getOptions();
        snackbarSuccessMessage('Standard Option has been deleted ^^');
      })
      .catch((e) => {
        snackbarErrorHandler(e);
        setIsLoading(false);
      });
  };

  return (
    <SuperAdminPageContainer>
      <Grid2 container direction={'row'} columnGap={1}>
        <StandardOptionsSelects selectValues={selectValues} setSelectValues={setSelectValues} />
        <Grid2 container flex={1}>
          <SelectInput label={t('SELECT_AN_OPTION_TO_ADD')} setValue={addStandardOption} options={filteredOptions} />
          <LoadingWrapper isLoading={isLoading}>
            {optionsAndStandardOptions?.standard_options.map(({ name, standard_option_id }) => (
              <li key={name}>
                <IconButton color="warning" onClick={() => removeStandardOption(standard_option_id)}>
                  <ClearIcon />
                </IconButton>
                {name}
              </li>
            ))}
          </LoadingWrapper>
        </Grid2>
      </Grid2>
    </SuperAdminPageContainer>
  );
};
