import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { UserPermission } from '@japieglobal/shared/src/api';
import { ColoredButton } from '@japieglobal/shared/src/components';
import { hasPermission } from '@japieglobal/shared/src/utils';

const CustomButton = styled(ColoredButton)({
  marginLeft: '10px',
});

export const CarsEmptyState = () => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  return (
    <div
      style={{
        minHeight: '50vh',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
      }}
    >
      <div style={{ margin: '20px' }}>
        {t('NO_RESULTS_SEARCH_QUERY')}
        {hasPermission(user, UserPermission.TAXATION) && (
          <CustomButton size="small" href="/taxatie">
            <img src="/images/hyperlink_button_taxation.png" height={30} alt="" />
          </CustomButton>
        )}
      </div>
    </div>
  );
};
