import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ClicksConfig, getClicksConfig, updateClicksConfig, ClicksConfigKey } from '@japieglobal/shared/src/api';
import { ColoredButton } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { ClicksItem } from './clicks-config-item';
import { GoogleUrls } from './clicks-google-urls';

export const ClicksConfigEdit = () => {
  const { t } = useTranslation();
  const { snackbarErrorHandler, snackbarSuccessMessage, snackbarErrorMessage } = useSnackbarErrorHandler();
  const [clicksConfig, setClicksConfig] = useState<ClicksConfig | undefined>(undefined);
  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const { dealer, propertyId } = useParams();

  useEffect(() => {
    if (dealer) {
      getClicksConfig(dealer)
        .then((configs) => {
          setClicksConfig(configs.find((config) => config[ClicksConfigKey.PROPERTY_ID].toString() === propertyId));
          setPropertyIds(configs.map((config) => config[ClicksConfigKey.PROPERTY_ID]));
        })
        .catch(snackbarErrorHandler);
    }
  }, [snackbarErrorHandler, dealer, propertyId]);

  const updateClickConfig = () => {
    if (clicksConfig && dealer && propertyId) {
      const existingIds = propertyIds.filter((id) => id !== Number(propertyId));
      if (existingIds.includes(Number(clicksConfig[ClicksConfigKey.PROPERTY_ID]))) {
        snackbarErrorMessage(t('PROPERTY_ID ALREADY EXISTS'));
      } else {
        updateClicksConfig(dealer, propertyId, clicksConfig)
          .then(() => {
            snackbarSuccessMessage(t('UPDATE_SUCCESS'));
            navigate(
              `/super-admin/clicks_config/edit/${clicksConfig[ClicksConfigKey.DEALER_NAME]}/${clicksConfig[ClicksConfigKey.PROPERTY_ID]}`,
            );
          })
          .catch(snackbarErrorHandler);
      }
    }
  };

  const onConfigChange = <K extends keyof ClicksConfig>(value: ClicksConfig[K], key: K) => {
    if (clicksConfig) {
      setClicksConfig({ ...clicksConfig, [key]: value });
    }
  };

  return (
    <SuperAdminPageContainer>
      {clicksConfig && dealer && (
        <Box p={2}>
          <h4>Update clicks config {clicksConfig.dealer_name}</h4>
          <ClicksItem clicksKey={ClicksConfigKey.REGEX} clicksConfig={clicksConfig} onConfigChange={onConfigChange} />
          <GoogleUrls dealer_name={dealer} />
          <ClicksItem
            clicksKey={ClicksConfigKey.CREDENTIALS}
            value={JSON.stringify(clicksConfig[ClicksConfigKey.CREDENTIALS])}
            multiline
            clicksConfig={clicksConfig}
            onConfigChange={onConfigChange}
          />
          <ClicksItem
            clicksKey={ClicksConfigKey.PROPERTY_ID}
            clicksConfig={clicksConfig}
            onConfigChange={onConfigChange}
          />
          <ClicksItem
            clicksKey={ClicksConfigKey.CLICKS_THRESHOLD}
            required={false}
            clicksConfig={clicksConfig}
            onConfigChange={onConfigChange}
          />
          <ColoredButton onClick={updateClickConfig}>Update</ColoredButton>
        </Box>
      )}
    </SuperAdminPageContainer>
  );
};
