import { TranslationFileType } from '../../i18n/i18n';
import { instance } from './instance';

export const valuateFile = async (
  body: FormData,
  props: {
    template: string;
    use_standard_options: boolean;
    use_paid_options: boolean;
    location_name: string | null;
    enable_other_countries: boolean;
    send_email: boolean;
  },
) =>
  instance.post(`/api/valuate/file`, body, {
    responseType: 'blob',
    params: props,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export enum UploadType {
  VIN = 'VIN',
  LEADS = 'LEADS',
  PURCHASE = 'PURCHASE',
  LOCATION = 'LOCATION',
  DEMO = 'DEMO',
  NEW = 'NEW',
  STOCK_DAYS_CLIENT = 'STOCK_DAYS_CLIENT',
  CAR_ATTRIBUTES = 'CAR_ATTRIBUTES',
}

export const uploadFile = async (body: FormData, dealer: string, props: { upload_type: UploadType; append: boolean }) =>
  instance
    .post(`/api/storage`, body, { params: props, headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res.data);

export const existsFile = async (dealer: string, props: { upload_type: UploadType }) =>
  instance.get(`/api/storage/exists`, { params: props }).then((res) => res.data);

export const deleteFile = async (dealer: string, props: { upload_type: UploadType }) =>
  instance.delete(`/api/storage`, { params: props }).then((res) => res.data);

export const downloadFile = async (dealer: string, props: { upload_type: UploadType }) =>
  instance.get(`/api/storage`, {
    responseType: 'blob',
    params: props,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const downloadTranslationFile = async (file_type: TranslationFileType) =>
  instance.get(`/translations/file`, {
    params: { file_type },
    responseType: 'blob',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const uploadTranslationFile = async (file_type: TranslationFileType, body: FormData) =>
  instance
    .post(`/translations`, body, { params: { file_type }, headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res);
