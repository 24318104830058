import React from 'react';
import { AppSearchParamKeys, NumberFilter } from '@japieglobal/shared/src/components';

export const TaxationFilterExtraCosts = () => (
  <NumberFilter
    min={0}
    max={1000000}
    labelTranslationKey="EXTRA_COSTS"
    searchParamKey={AppSearchParamKeys.EXTRA_COSTS}
    isMoneyInput
    hasBackendDefault
  />
);
